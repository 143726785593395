import MyLatex from "../../components/MyLatex/MyLatex";
import "./LatexHelp.css";

const LatexHelp = () => {
  const commands = [
    { name: "Multiplikation", formula: "a \\cdot b" },
    { name: "Potenz", formula: "a^b = c^{d+1}" },
    { name: "Index", formula: "x_1, x_{i+1}" },
    { name: "Bruch", formula: "\\frac{a+b}{c}" },
    { name: "Modulo", formula: "a \\equiv b \\nequiv c \\pmod{p}" },
    { name: "Ungleichungen", formula: "a \\leq b < c \\geq d" },
    { name: "Ungefähr", formula: "a \\approx b \\gtrapprox c \\lessapprox d" },
    { name: "Ungleichheit", formula: "a \\neq b" },
    { name: "Implikationen", formula: "\\iff, \\implies, \\impliedby" },
    { name: "Pünktchen", formula: "x_1 + \\dots + x_n" },
    { name: "Tiefe Pünktchen", formula: "x_1 + \\ldots + x_n" },
    { name: "Vertikale Pünktchen", formula: "\\vdots" },
    { name: "Teilbarkeit", formula: "a \\mid b, a \\nmid c" },
    { name: "Große Klammern", formula: "\\left( \\frac{a^2}{b} \\right)" },
    { name: "Betrag", formula: "|a|, \\left| \\frac{a}{b} \\right|" },
    { name: "Gaußklammern", formula: "\\lfloor a \\rfloor, \\lceil a \\rceil" },
    {
      name: "Große Gaußklammern",
      formula: "\\left\\lfloor \\frac{a}{b} \\right\\rfloor",
    },
    { name: "ggT, kgV", formula: "\\ggT(a, b), \\kgV(a, b)", custom: true },
    { name: "gcd, lcm", formula: "\\gcd(a, b), \\lcm(a, b)", custom: true },
    { name: "Ordnung", formula: "\\ord_p(x)", custom: true },
    { name: "Grad", formula: "\\deg", custom: true },
    {
      name: "Zahlenmengen",
      formula: "\\IP, \\IN, \\IZ, \\IQ, \\IR, \\IC",
      custom: true,
    },
    {
      name: "Menge",
      formula: "\\{ a \\in \\IN \\mid a \\equiv 2 \\pmod{p} \\}",
    },
    { name: "Element", formula: "n \\in \\IN" },
    { name: "Summe", formula: "\\sum_{i=0}^n i^2" },
    { name: "Produkt", formula: "\\prod_{i=0}^n i^2" },
    { name: "Leerzeichen", formula: "a ~ b" },
    { name: "QED", formula: "\\qed", custom: true },
    { name: "Linke/Rechte Seite", formula: "\\LHS, \\RHS", custom: true },
    { name: "Text", formula: "a=b \\text{~~~ und ~~~} b=c" },
    {
      name: "Griechische Buchstaben",
      formula: "\\alpha, \\beta, \\mu, \\nu, \\Omega, \\ldots",
    },
  ];
  return (
    <section className="latex-help-section">
      <h2>
        <MyLatex>$\LaTeX$</MyLatex>-Hilfe
      </h2>
      <div className="description">
        <p>
          Um eine Formel in deine Abgabe einzufügen, schreibe sie in
          Dollarzeichen:
          <br />
          <code>$a^2 + b^2 = c^2$</code>.
        </p>
        <p>
          Formeln in einer getrennten Zeile erhältst du mit zwei Dollarzeichen:
          <br />
          <code>$$ a^2 + b^2 = c^2 $$</code>.
        </p>
        <p>
          Mit <code>{"\\tag{1}"}</code> kannst du Formeln in getrennten Zeilen
          nummerieren: <br />
          <code>{"$$ a^2 + b^2 = c^2 \\tag{1} $$"}</code>
          <br /> wird zu <br />
          <MyLatex>{"$$ a^2 + b^2 = c^2 \\tag{1}. $$"}</MyLatex>
        </p>
        <p>
          Unten findest du eine Übersicht über die wichtigsten{" "}
          <MyLatex>$\LaTeX$</MyLatex>-Befehle.
          <br />
          (Die gelb hinterlegten Befehle sind benutzerdefiniert, also von mir
          eingefügt.) <br />
        </p>
        <p>
          Für weitere Symbole ist folgende Seite nützlich:{" "}
          <a
            href="https://detexify.kirelabs.org/classify.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://detexify.kirelabs.org/classify.html
          </a>
          <br />
          Und hier ist eine noch längere Liste:{" "}
          <a
            href="https://de.wikipedia.org/wiki/Liste_mathematischer_Symbole"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://de.wikipedia.org/wiki/Liste_mathematischer_Symbole
          </a>
        </p>
      </div>
      <table className="latex-help-table">
        <thead>
          <tr>
            <th>Stichwort</th>
            <th>Befehl</th>
            <th>Ausgabe</th>
          </tr>
        </thead>
        <tbody>
          {commands.map((command, i) => (
            <tr key={i} className={command?.custom ? "custom-macro" : null}>
              <td>{command.name}</td>
              <td>
                <code>{command.formula}</code>
              </td>
              <td>
                <MyLatex>{"$" + command.formula + "$"}</MyLatex>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default LatexHelp;
