import { BrowserRouter } from "react-router-dom";

import { AuthWrapper } from "./components/AuthWrapper";
import ScrollToTop from "./components/ScrollToTop";

//import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <BrowserRouter basename="/">
      <ScrollToTop />
      <AuthWrapper />
    </BrowserRouter>
  );
}

export default App;

/* To do:
- Error 404 Page
- Design Navbar
- Design body
*/
