import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../components/Api";
import LatexEditor from "../../components/LatexEditor/LatexEditor";
import ProblemByUrl from "../../components/Problem/ProblemByUrl";
import "./GradeSolution.css";
import { AuthData } from "../../components/AuthWrapper";

const GradeSolution = () => {
  const { checkToken } = AuthData();

  const navigate = useNavigate();

  const { submissionId } = useParams();

  const localStorageKey = "correction-" + submissionId;

  const [feedback, setFeedback] = useState({
    submission_id: submissionId,
    score: 0,
    feedback: "",
  });

  const [data, setData] = useState();

  const handleChange = (value) => {
    setFeedback((prev) => ({ ...prev, feedback: value }));
    setSubmitMessage("");

    if (value !== "") {
      localStorage.setItem(localStorageKey, value);
    } else {
      localStorage.removeItem(localStorageKey);
    }
  };

  const handleScoreChange = (e) => {
    setFeedback((prev) => ({ ...prev, score: Number(e.target.value) }));
    setSubmitMessage("");
  };

  const [submitMessage, setSubmitMessage] = useState(null);

  const handleClick = async (e) => {
    e.preventDefault();
    if (feedback.feedback !== null && feedback.feedback.trim() !== "") {
      if (feedback.score in [0, 1, 2, 3, 4, 5, 6, 7]) {
        try {
          api
            .patch("/submission/" + submissionId, feedback)
            .then((res) => {
              if (res?.data?.message === "Not authorized") {
                checkToken();
              } else {
                localStorage.removeItem(localStorageKey);
                setSubmitMessage("Erfolgreich abgesendet!");
                navigate("/admin");
              }
            })
            .catch((err) => {
              setSubmitMessage(
                "Es konnte keine Verbindung zum Server aufgebaut werden. Bitte versuche es noch einmal oder überprüfe deine Verbindung!"
              );
            });
        } catch (err) {}
      } else {
        setSubmitMessage("Bitte gib eine gültige Punktzahl ein!");
      }
    } else {
      setSubmitMessage("Bitte gib das Feedback ein!");
    }
  };

  const [loadingEditor, setLoadingEditor] = useState(true);

  useEffect(() => {
    setFeedback((prev) => ({
      ...prev,
      feedback: localStorage.getItem(localStorageKey),
    }));
    setLoadingEditor(false);
  }, [localStorageKey]);

  const [validProblem, setValidSubmission] = useState(null);
  const [message, setMessage] = useState("Laden...");

  useEffect(() => {
    const fetchSubmission = async () => {
      try {
        api.get(`/submission/${submissionId}`).then((res) => {
          if (res.data.length !== 0) {
            setData(res.data[0]);
            if (
              feedback.feedback === "" &&
              (localStorage.getItem(localStorageKey) === null) | ""
            ) {
              setFeedback((prev) => ({
                ...prev,
                feedback: res.data[0].feedback ?? res.data[0].solution,
                score: res.data[0].score ?? 0,
              }));
            }
            setValidSubmission(true);
            setMessage("");
          } else {
            setValidSubmission(false);
            setMessage("Ungültige Abgabenummer.");
          }
        });
      } catch (err) {}
    };
    fetchSubmission();
  }, [submissionId]);

  return (
    <section className="grade-solution-wrapper">
      <h1>Abgabe bewerten</h1>
      <div>
        Eingereicht von {data?.user_first_name + " " + data?.user_last_name} am{" "}
        {moment(data?.datetime).format("DD.MM.YYYY, HH:mm")}
      </div>
      {!validProblem ? (
        message
      ) : (
        <>
          <div className="form">
            <ProblemByUrl url={data?.problem_url} />
            {loadingEditor ? (
              <p>Lade Editor...</p>
            ) : (
              <LatexEditor
                handleChangeFunc={handleChange}
                defaultValue={feedback.feedback}
              />
            )}
            {submitMessage && (
              <div className="submit-message">{submitMessage}</div>
            )}
            Punktzahl:{" "}
            <input
              type="number"
              name="score"
              defaultValue={data?.score ?? 0}
              onChange={handleScoreChange}
              min={0}
              max={7}
            />{" "}
            <br />
            <input type="button" value="Absenden" onClick={handleClick} />
          </div>
          <p>Originale Lösung:</p>
          <div className="original-solution">
            <code>{data?.solution}</code>
          </div>
          {data?.feedback && (
            <>
              <p>
                Letztes Feedback:{" "}
                {data?.score !== null ? data?.score + " Punkte" : null}
              </p>
              <div className="original-solution">
                <code>{data?.feedback}</code>
              </div>
            </>
          )}
        </>
      )}
    </section>
  );
};

export default GradeSolution;
