import axios from "axios";
// import { AuthData } from "./AuthWrapper";

// const { checkToken } = AuthData();

export const api = axios.create({
  baseURL: "https://www.chrono42.de",
  withCredentials: true,
  headers: {
    "Accept": "application/json",
  },
});

/* api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Anything except 2XX goes to here
    const status = error.response?.status || 500;
    if (status === 401) {
      checkToken();
    } else {
      return Promise.reject(error); // Delegate error to calling side
    }
  }
); */

/*
import axios from "axios";

export const api = axios.create({
  baseURL: "https://www.chrono42.de",
  withCredentials: true,
  headers: {
    "Accept": "application/json",
  },
});
*/
