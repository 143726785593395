import Latex from "react-latex-next";
import "katex/dist/katex.min.css";

const MyLatex = (props) => {
  const macros = {
    "\\nequiv": "\\not\\equiv",
    "\\qed": "\\Box",
    "\\IP": "\\mathbb{P}",
    "\\IN": "\\mathbb{N}",
    "\\IZ": "\\mathbb{Z}",
    "\\IQ": "\\mathbb{Q}",
    "\\IR": "\\mathbb{R}",
    "\\IC": "\\mathbb{C}",
    "\\gcd": "\\operatorname{gcd}",
    "\\lcm": "\\operatorname{lcm}",
    "\\ggT": "\\operatorname{ggT}",
    "\\kgV": "\\operatorname{kgV}",
    "\\ord": "\\operatorname{ord}",
    "\\deg": "\\operatorname{deg}",
    "\\epsilon": "\\varepsilon",
    "\\phi": "\\varphi",
    "\\LHS": "\\mathrm{LHS}",
    "\\RHS": "\\mathrm{RHS}",
  };

  return (
    <span className="mylatex">
      <Latex macros={macros}>{props.children ?? ""}</Latex>
    </span>
  );
};

export default MyLatex;
