import { useEffect, useState } from "react";
import { api } from "../Api";
import { AuthData } from "../AuthWrapper";

const ScoreboardConsent = () => {
  const { checkToken } = AuthData();

  const [scoreboardConsent, setScoreboardConsent] = useState(true);

  useEffect(() => {
    const fetchScoreboardConsent = async () => {
      try {
        api.get("/scoreboard-consent").then((res) => {
          if (res?.data?.message === "Not authorized") {
            checkToken();
          } else {
            setScoreboardConsent(res.data[0].scoreboard_consent === 1);
            setLoading(false);
          }
        });
      } catch (err) {}
    };
    fetchScoreboardConsent();
  }, []);

  const [loading, setLoading] = useState(true);

  const handleClick = async (e) => {
    try {
      api
        .patch("/scoreboard-consent", {
          scoreboardConsent: e.target.checked ? 1 : 0,
        })
        .then((res) => {
          if (res?.data?.message === "Not authorized") {
            checkToken();
          }
        });
    } catch (err) {}
  };

  if (loading) {
    return <>Laden...</>;
  } else {
    return (
      <>
        <input
          type="checkbox"
          onChange={handleClick}
          id="scoreboard-consent"
          defaultChecked={scoreboardConsent}
        />{" "}
        <label htmlFor="scoreboard-consent">
          Ich möchte für die anderen Teilnehmer auf der Rangliste sichtbar sein.
        </label>
      </>
    );
  }
};

export default ScoreboardConsent;
