import { NavLink } from "react-router-dom";
import { AuthData } from "../AuthWrapper";
import { nav } from "../Navigation";
import "./Navbar.css";
import { useEffect, useState } from "react";

const NavItem = ({ path, name, handleLinkClickFunc }) => {
  return (
    <li>
      <NavLink to={path} className="nav-link" onClick={handleLinkClickFunc}>
        {name}
      </NavLink>
    </li>
  );
};

const Navbar = () => {
  const { user, logout } = AuthData();
  const [showMenu, setShowMenu] = useState(false);
  const { checkToken } = AuthData();

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 1150;

  const handleLinkClick = () => {
    if (isMobile) {
      setShowMenu(false);
    }
    checkToken();
  };
  let navItems = (
    <ul>
      {nav.map((route, i) => {
        if (route.isMenu) {
          if (!route.isAdmin || user.role === "admin") {
            return (
              <NavItem
                key={i}
                path={route.menuPath ?? route.path}
                name={route.name}
                handleLinkClickFunc={handleLinkClick}
              />
            );
          }
        }
        return null;
      })}
      {user.isAuthenticated ? (
        <li>
          <a
            onClick={() => {
              logout();
            }}
          >
            Abmelden
          </a>
        </li>
      ) : null}
    </ul>
  );

  let navbarMobile = (
    <ul>
      <li>
        <a onClick={toggleMenu}>{showMenu ? "Menü schließen" : "Menü"}</a>
      </li>
    </ul>
  );

  return (
    <>
      <nav className="navbar">{isMobile ? navbarMobile : navItems}</nav>
      {isMobile && showMenu && <nav className="mobile-navbar">{navItems}</nav>}
    </>
  );
};

export default Navbar;
