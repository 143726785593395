import { NavLink } from "react-router-dom";

const Error404 = () => {
  return (
    <section>
      <h2>Error 404</h2>
      <p>Die aufgerufene Seite existiert nicht.</p>
      <NavLink to="/">Zur Startseite</NavLink>
    </section>
  );
};

export default Error404;
