import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { api } from "../../components/Api";
import MyLatex from "../../components/MyLatex/MyLatex";
import ScoreboardConsent from "../../components/ScoreboardConsent/ScoreboardConsent";
import "./Home.css";
import { AuthData } from "../../components/AuthWrapper";

const Home = () => {
  const [name, setName] = useState(null);
  const { checkToken } = AuthData();

  useEffect(() => {
    const fetchName = async () => {
      try {
        api.get("/user").then((res) => {
          if (res.data.message === "Not authorized") {
            checkToken();
          } else {
            setName(res.data[0].first_name);
          }
        });
      } catch (err) {}
    };
    fetchName();
  }, []);

  return (
    <section className="home-section">
      {name === null ? (
        <div className="loading-message">Laden...</div>
      ) : (
        <>
          <h2>Willkommen beim Herbstzirkel!</h2>

          <div className="settings">
            <h3>Einstellungen</h3>
            <div className="settings-scoreboard-consent">
              <ScoreboardConsent />
            </div>
            <div className="settings-change-password">
              <NavLink to="/change-password">Passwort ändern</NavLink>
            </div>
          </div>

          <div className="information">
            <h3>Informationen</h3>
            <p>Liebe(r) {name},</p>
            <p>
              herzlich willkommen auf der Webseite zum 4. Herbstzirkel-Brief
              2023! Hier die wichtigsten Infos:
            </p>
            <ul>
              <li>
                Unter <NavLink to="/number-theory">Zahlentheorie</NavLink>{" "}
                findest du den Theorieteil sowie die Einsendeaufgaben.
              </li>
              <li>
                Bei jeder Aufgabe steht ein Link zum Abgeben von Lösungen. Bei
                Fragen schau unter{" "}
                <NavLink to="/latex-help">
                  <MyLatex>$\LaTeX$</MyLatex>-Hilfe
                </NavLink>{" "}
                nach oder melde dich bei mir.
              </li>
              <li>
                Gib gerne auch unvollständige Ansätze ab! Du bekommst dann einen
                Tipp, wie es weitergeht.
              </li>
              <li>
                Auf jede Aufgabe gibt es 7 Punkte. Du kannst jederzeit mehrere
                Abgaben hintereinander einreichen, deine Punktzahl wird dann
                ggf. erhöht. Deine Abgaben und die Korrekturen findest du unter{" "}
                <NavLink to="dashboard">Meine Abgaben</NavLink>.
              </li>
              <li>
                In der <NavLink to="scoreboard">Rangliste</NavLink> kannst du
                sehen, wo die anderen gerade stehen, sofern du unten ankreuzt,
                dass du auch sichtbar für die anderen sein möchtest.
              </li>
              <li>
                Ich würde mich sehr über dein (anonymes){" "}
                <NavLink to="feedback">Feedback</NavLink> freuen, insbesondere
                bin ich für Hinweise auf Bugs dankbar!
              </li>
              <li>
                Fragen kannst du direkt in eine Abgabe zu einer Aufgabe
                schreiben (sonst natürlich auch per Mail oder{" "}
                <NavLink to="feedback">Feedback</NavLink>).
              </li>
            </ul>
            <p>Ich wünsche dir viel Spaß!</p>
            <p>Christian</p>
          </div>
        </>
      )}
    </section>
  );
};
export default Home;
