import { useEffect, useState } from "react";
import { AuthData } from "../../components/AuthWrapper";
import "./Login.css";

const Login = () => {
  const { login, getSessionTimeout } = AuthData();
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrorMessage(null);

    if (!formData.username || !formData.password) {
      setErrorMessage("Bitte alle Felder ausfüllen!");
      return;
    }

    try {
      await login(formData.username, formData.password).then(null, (e) => {
        setErrorMessage("Benutzername oder Passwort falsch!");
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (getSessionTimeout()) {
      setErrorMessage(
        "Deine Sitzung ist abgelaufen. Bitte melde dich erneut an!"
      );
    }
  });

  return (
    <section className="login-wrapper">
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="form-item">
          <h2>Herbstzirkel 2023</h2>
        </div>
        <div className="form-item">
          <input
            name="username"
            onChange={handleChange}
            type="text"
            placeholder="Benutzername"
          />
          <input
            name="password"
            onChange={handleChange}
            type="password"
            placeholder="Passwort"
          />
          {errorMessage ? (
            <div className="login-error">{errorMessage}</div>
          ) : null}
          <input type="submit" value="Anmelden" />
        </div>
      </form>
    </section>
  );
};
export default Login;
