import MyLatex from "../MyLatex/MyLatex";
import "./Colorbox.css";

const Colorbox = ({
  header,
  children,
  footer,
  primaryBackground,
  primaryColor,
  secondaryBackground,
  secondaryColor,
}) => {
  return (
    <div
      className="colorbox"
      style={{
        background: secondaryBackground ?? null,
        color: secondaryColor ?? null,
      }}
    >
      <div
        className="colorbox-header"
        style={{
          background: primaryBackground ?? null,
          color: primaryColor ?? null,
        }}
      >
        {header}
      </div>
      <div className="colorbox-text">
        <MyLatex>{children}</MyLatex>
      </div>
      {footer && <div className="colorbox-footer">{footer}</div>}
    </div>
  );
};

export default Colorbox;
