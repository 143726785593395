import AdminDashboard from "../pages/AdminDashboard/AdminDashboard";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import Dashboard from "../pages/Dashboard/Dashboard";
import Error404 from "../pages/Error404/Error404";
import Feedback from "../pages/Feedback/Feedback";
import GradeSolution from "../pages/GradeSolution/GradeSolution";
import HerbstzirkelZahlentheorie from "../pages/HerbstzirkelZahlentheorie/HerbstzirkelZahlentheorie";
import Home from "../pages/Home/Home";
import LatexHelp from "../pages/LatexHelp/LatexHelp";
import Scoreboard from "../pages/Scoreboard/Scoreboard";
import SubmitSolution from "../pages/SubmitSolution/SubmitSolution";
import MyLatex from "./MyLatex/MyLatex";

export const nav = [
  {
    path: "/",
    name: "Startseite",
    element: <Home />,
    isMenu: true,
    isAdmin: false,
  },
  {
    path: "/number-theory",
    name: "Zahlentheorie",
    element: <HerbstzirkelZahlentheorie />,
    isMenu: true,
    isAdmin: false,
  },
  {
    path: "/dashboard/:problemUrl?",
    menuPath: "/dashboard",
    name: "Meine Abgaben",
    element: <Dashboard />,
    isMenu: true,
    isAdmin: false,
  },
  {
    path: "/scoreboard",
    name: "Rangliste",
    element: <Scoreboard />,
    isMenu: true,
    isAdmin: false,
  },
  {
    path: "/latex-help",
    name: <MyLatex>$\LaTeX$-Hilfe</MyLatex>,
    element: <LatexHelp />,
    isMenu: true,
    isAdmin: false,
  },
  {
    path: "/feedback",
    name: "Feedback",
    element: <Feedback />,
    isMenu: true,
    isAdmin: false,
  },
  {
    path: "/admin",
    name: "Administration",
    element: <AdminDashboard />,
    isMenu: true,
    isAdmin: true,
  },
  {
    path: "/submit/:problemUrl",
    element: <SubmitSolution />,
    isMenu: false,
    isAdmin: false,
  },
  {
    path: "/grade/:submissionId",
    element: <GradeSolution />,
    isMenu: false,
    isAdmin: true,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
    isMenu: false,
    isAdmin: false,
  },
  {
    path: "*",
    element: <Error404 />,
    isMenu: false,
    isAdmin: false,
  },
];
