import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { api } from "../../components/Api";
import LatexEditor from "../../components/LatexEditor/LatexEditor";
import ProblemByUrl from "../../components/Problem/ProblemByUrl";
import { AuthData } from "../../components/AuthWrapper";

const SubmitSolution = () => {
  const { checkToken } = AuthData();

  const navigate = useNavigate();

  const { problemUrl } = useParams();

  const localStorageKey = "solution-" + problemUrl;

  const [solution, setSolution] = useState({
    problem: problemUrl,
    solution: "",
  });

  const handleChange = (value) => {
    setSolution((prev) => ({ ...prev, solution: value }));
    setSubmitMessage("");

    if (value !== "") {
      localStorage.setItem(localStorageKey, value);
    } else {
      localStorage.removeItem(localStorageKey);
    }
  };

  const [submitMessage, setSubmitMessage] = useState(null);

  const handleClick = async (e) => {
    e.preventDefault();
    if (solution.solution !== null && solution.solution.trim() !== "") {
      try {
        api
          .post("/submissions", solution)
          .then((res) => {
            if (res?.data?.message === "Not authorized") {
              checkToken();
            } else {
              localStorage.removeItem(localStorageKey);
              setSubmitMessage("Erfolgreich abgesendet!");
              navigate("/dashboard/" + problemUrl);
            }
          })
          .catch((err) => {
            setSubmitMessage(
              "Es konnte keine Verbindung zum Server aufgebaut werden. Bitte versuche es noch einmal oder überprüfe deine Verbindung!"
            );
          });
      } catch (err) {}
    } else {
      setSubmitMessage("Bitte gib deine Lösung ein!");
    }
  };

  const [loadingEditor, setLoadingEditor] = useState(true);

  useEffect(() => {
    setSolution((prev) => ({
      ...prev,
      solution: localStorage.getItem(localStorageKey),
    }));
    setLoadingEditor(false);
  }, [localStorageKey]);

  const [validProblem, setValidProblem] = useState(null);
  const [message, setMessage] = useState("Laden...");

  useEffect(() => {
    const fetchProblem = async () => {
      try {
        api.get(`/problem/${problemUrl}`).then((res) => {
          if (res.data.length !== 0) {
            setValidProblem(true);
            setMessage("");
          } else {
            setValidProblem(false);
            setMessage("Ungültige Aufgabe.");
          }
        });
      } catch (err) {}
    };
    fetchProblem();
  }, [problemUrl]);

  return (
    <section className="submit-solution-wrapper">
      <h1>Ideen abgeben</h1>
      <div>
        →{" "}
        <NavLink to={"/dashboard/" + problemUrl}>
          Zu meinen früheren Abgaben
        </NavLink>
      </div>
      {!validProblem ? (
        message
      ) : (
        <>
          <div className="form">
            <ProblemByUrl url={problemUrl} />

            {loadingEditor ? (
              <p>Lade Editor...</p>
            ) : (
              <LatexEditor
                handleChangeFunc={handleChange}
                defaultValue={solution.solution}
              />
            )}

            {submitMessage && (
              <div className="submit-message">{submitMessage}</div>
            )}

            <input type="button" value="Absenden" onClick={handleClick} />
          </div>
        </>
      )}
    </section>
  );
};

export default SubmitSolution;
