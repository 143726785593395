import { useEffect, useState } from "react";

import { NavLink } from "react-router-dom";
import { api } from "../../components/Api";
import { AuthData } from "../../components/AuthWrapper";
import "./Scoreboard.css";

const Score = ({ s }) => {
  return <td className={"score score" + s}>{s}</td>;
};

const ScoreboardRow = ({ scoreUser }) => {
  const { user } = AuthData();

  return (
    <tr>
      <td>{scoreUser.first_name + " " + scoreUser.last_name}</td>
      <Score s={scoreUser.score_n1}></Score>
      <Score s={scoreUser.score_n2}></Score>
      <Score s={scoreUser.score_n3}></Score>
      <Score s={scoreUser.score_n4}></Score>
      <Score s={scoreUser.score_n5}></Score>
      <Score s={scoreUser.score_n6}></Score>
      <Score s={scoreUser.score_n7}></Score>
      <Score s={scoreUser.score_n8}></Score>
      <Score s={scoreUser.score_n9}></Score>
      <Score s={scoreUser.score_n10}></Score>
      <Score s={scoreUser.score_n11}></Score>
      <Score s={scoreUser.score_n12}></Score>
      {user.role === "admin" && <td>{scoreUser.group}</td>}
      <td className="score">{scoreUser.score_total}</td>
    </tr>
  );
};

const Scoreboard = () => {
  const { user } = AuthData();
  const [users, setUsers] = useState([]);

  const [scoreboardConsent, setScoreboardConsent] = useState(true);

  useEffect(() => {
    const fetchScoreboardConsent = async () => {
      try {
        api.get("/scoreboard-consent").then((res) => {
          setScoreboardConsent(res.data[0].scoreboard_consent === 1);
        });
      } catch (err) {}
    };
    fetchScoreboardConsent();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        api.get("/userScores").then((res) => {
          setUsers(res.data);
        });
      } catch (err) {}
    };
    fetchUsers();
  }, []);

  const problems = [
    { name: "N1", url: "n1" },
    { name: "N2", url: "n2" },
    { name: "N3", url: "n3" },
    { name: "N4", url: "n4" },
    { name: "N5", url: "n5" },
    { name: "N6", url: "n6" },
    { name: "N7", url: "n7" },
    { name: "N8", url: "n8" },
    { name: "N9", url: "n9" },
    { name: "N10", url: "n10" },
    { name: "N11", url: "n11" },
    { name: "N12", url: "n12" },
  ];

  let scoreboard;
  if (users.length === 0) {
    scoreboard = <p>Lade Rangliste...</p>;
  } else {
    scoreboard = (
      <table className="scoreboard">
        <thead>
          <tr>
            <th>Name</th>
            {problems.map((problem, i) => (
              <th key={i}>
                <NavLink to={"/dashboard/" + problem.url}>
                  {problem.name}
                </NavLink>
              </th>
            ))}
            {user.role === "admin" && <th>Gruppe</th>}
            <th>Summe</th>
          </tr>
        </thead>
        <tbody>
          {users.map((scoreUser, i) => (
            <ScoreboardRow key={i} scoreUser={scoreUser} />
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <section className="scoreboard-section">
      <div className="scoreboard-title">
        <h2>Rangliste</h2>
        {!scoreboardConsent && (
          <p>
            Um die anderen Teilnehmer zu sehen, bestätige auf der{" "}
            <NavLink to="/">Startseite</NavLink>, dass du auch für sie sichtbar
            sein möchtest!
          </p>
        )}
      </div>
      <div className="scoreboard-wrapper">{scoreboard}</div>
    </section>
  );
};

export default Scoreboard;
