import Colorbox from "../Colorbox/Colorbox";

const Example = ({ number, children }) => {
  return (
    <>
      <Colorbox
        header={"Beispiel " + number}
        primaryBackground="#e74c3c"
        secondaryBackground="#ffcccc"
        primaryColor="white"
      >
        {children}
      </Colorbox>
    </>
  );
};

export default Example;
