import { Link } from "react-router-dom";
import Colorbox from "../Colorbox/Colorbox";

const Problem = ({
  number,
  url,
  showSubmitLink,
  showDashboardLink,
  children,
}) => {
  let submitLink;
  if (showSubmitLink || showDashboardLink) {
    submitLink = (
      <div className="problem-submit-link">
        {showSubmitLink && (
          <p>
            → <Link to={"/submit/" + url}>Ideen aufschreiben</Link>
          </p>
        )}
        {showDashboardLink && (
          <p>
            → <Link to={"/dashboard/" + url}>Meine Abgaben</Link>
          </p>
        )}
      </div>
    );
  }
  return (
    <>
      <Colorbox
        header={"Aufgabe " + number}
        footer={submitLink}
        primaryBackground="#e74c3c"
        secondaryBackground="#ffcccc"
        primaryColor="white"
      >
        {children}
      </Colorbox>
    </>
  );
};

export default Problem;
