import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Login from "../pages/Login/Login";
import { api } from "./Api";
import RenderRoutes from "./RenderRoutes";
import ErrorServerDown from "../pages/ErrorServerDown/ErrorServerDown";

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

export const AuthWrapper = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    name: "",
    isAuthenticated: null,
    role: null,
  });

  const [sessionTimeout, setSessionTimeout] = useState(false);

  const [loadingMessage, setLoadingMessage] = useState("");

  const login = (username, password) => {
    // Make a call to the authentication API to check the username

    return new Promise((resolve, reject) => {
      api
        .post("/login", { username: username, password: password })
        .then(function (res) {
          if (res.status === 201) {
            setSessionTimeout(false);
            setUser({
              name: username,
              isAuthenticated: true,
              role: res.data.role,
            });
            resolve("success");
          } else {
            reject("incorrect credentials");
          }
        })
        .catch(function (err) {});
    });
  };

  const checkToken = async () => {
    try {
      api
        .request("/checkToken", {
          method: "get",
        })
        .then((res) => {
          if (res.data.message === "Authorized") {
            setSessionTimeout(false);
            setUser({
              name: res.data.username,
              isAuthenticated: true,
              role: res.data.role,
            });
          } else {
            if (user.isAuthenticated) {
              setSessionTimeout(true);
            }
            setUser({
              name: "",
              isAuthenticated: false,
              role: null,
            });
          }
        })
        .catch((err) => {
          setLoadingMessage(<ErrorServerDown />);
        });
    } catch (err) {
      setUser({ name: "", isAuthenticated: false, role: null });
    }
  };

  const getSessionTimeout = () => {
    if (sessionTimeout) {
      setSessionTimeout(false);
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  const logout = () => {
    const logoutApi = async () => {
      try {
        api.get("/logout").then(() => {
          setUser({ ...user, isAuthenticated: false });
          navigate("/");
        });
      } catch (err) {}
    };
    logoutApi();
  };

  let rendering;

  if (user.isAuthenticated === null) {
    rendering = <div className="loading-message">{loadingMessage}</div>;
  } else if (user.isAuthenticated === false) {
    rendering = <Login />;
  } else {
    rendering = <RenderRoutes />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        checkToken,
        getSessionTimeout,
      }}
    >
      {rendering}
    </AuthContext.Provider>
  );
};
