import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import iconSchedule from "../../assets/icons/schedule.svg";
import Submissions from "../Submissions/Submissions";
import "./Dashboard.css";
import { api } from "../../components/Api";
import { AuthData } from "../../components/AuthWrapper";

const Dashboard = () => {
  const [problems, setProblems] = useState([]);
  const { checkToken } = AuthData();
  const [scores, setScores] = useState({});

  useEffect(() => {
    const fetchAllProblems = async () => {
      try {
        api.get("/problems/number-theory").then((res) => {
          if (res.data.message === "Not authorized") {
            checkToken();
          } else {
            setProblems(res.data);
          }
        });
      } catch (err) {}
    };
    fetchAllProblems();

    const fetchScores = async () => {
      try {
        api.get("/scores").then((res) => {
          if (res.data.message === "Not authorized") {
            checkToken();
          } else {
            setScores({
              n1: res.data[0].score_n1,
              n2: res.data[0].score_n2,
              n3: res.data[0].score_n3,
              n4: res.data[0].score_n4,
              n5: res.data[0].score_n5,
              n6: res.data[0].score_n6,
              n7: res.data[0].score_n7,
              n8: res.data[0].score_n8,
              n9: res.data[0].score_n9,
              n10: res.data[0].score_n10,
              n11: res.data[0].score_n11,
              n12: res.data[0].score_n12,
            });
          }
        });
      } catch (err) {}
    };

    fetchScores();
  }, [checkToken]);

  return (
    <section className="dashboard-section">
      <h2>Meine Abgaben</h2>
      <div className="dashboard">
        <div className="dashboard-problems">
          <NavLink end to="/dashboard" className="dashboard-problem-link">
            <div className="dashboard-problem-icon">
              <img src={iconSchedule} alt={"New"} />
            </div>
          </NavLink>

          {problems.map((problem, i) => (
            <NavLink
              key={i}
              to={"/dashboard/" + problem.url}
              className="dashboard-problem-link"
            >
              <div className="dashboard-problem-number">{problem.number}</div>
              <div
                className={
                  "dashboard-problem-score score" + scores[problem.url]
                }
              >
                {scores[problem.url]}
              </div>
            </NavLink>
          ))}
        </div>
        <div className="dashboard-submissions">{<Submissions />}</div>
      </div>
    </section>
  );
};

export default Dashboard;
