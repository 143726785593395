import moment from "moment";
import { useEffect, useState } from "react";
import { api } from "../../components/Api";
import { NavLink } from "react-router-dom";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const [submissions, setSubmissions] = useState([]);
  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    const fetchAllSubmissions = async () => {
      try {
        api.get("/submissions").then((res) => {
          setSubmissions(res.data);
        });
      } catch (err) {}
    };
    fetchAllSubmissions();

    const fetchFeedback = async () => {
      try {
        api.get("/feedback").then((res) => {
          setFeedback(res.data);
        });
      } catch (err) {}
    };
    fetchFeedback();
  }, []);

  return (
    <section className="admin-section">
      <h2>Admin</h2>

      <div className="submissions">
        <h3>Abgaben</h3>

        <div className="submissions-wrapper">
          <table>
            <thead>
              <tr>
                {/*<th>Status</th>*/}
                <th>Datum</th>
                <th>Name</th>
                <th>Aufgabe</th>
                <th>Punktzahl</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {submissions.map((submission, i) => (
                <tr key={i}>
                  {/*<td>{submission.status}</td>*/}
                  <td>
                    {moment(submission.datetime).format("DD.MM.YYYY, HH:mm")}
                  </td>
                  <td>
                    {submission.user_first_name +
                      " " +
                      submission.user_last_name}
                  </td>
                  <td>{submission.problem_number}</td>
                  <td>{submission.score}</td>
                  <td>
                    <NavLink to={"/grade/" + submission.id}>Bewerten</NavLink>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="feedback-dashboard">
        <h3>Feedback</h3>
        <div className="feedback-wrapper">
          <table>
            <thead>
              <tr>
                <th>Datum</th>
                <th>Feedback</th>
              </tr>
            </thead>
            <tbody>
              {feedback.map((feedback_entry, i) => (
                <tr key={i}>
                  <td>
                    {moment(feedback_entry.datetime).format(
                      "DD.MM.YYYY, HH:mm"
                    )}
                  </td>
                  <td className="feedback-td">{feedback_entry.feedback}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default AdminDashboard;
