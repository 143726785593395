import { useEffect, useState } from "react";
import { AuthData } from "../../components/AuthWrapper";
import "./ChangePassword.css";
import { useNavigate } from "react-router-dom";
import { api } from "../../components/Api";

const ChangePassword = () => {
  const { login, getSessionTimeout } = AuthData();
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    newPasswordConfirm: "",
  });
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setErrorMessage(null);

    if (
      !formData.oldPassword ||
      !formData.newPassword ||
      !formData.newPasswordConfirm
    ) {
      setErrorMessage("Bitte alle Felder ausfüllen!");
      return;
    }

    if (formData.newPassword !== formData.newPasswordConfirm) {
      setErrorMessage("Neues Passwort und Bestätigung stimmen nicht überein!");
      return;
    }

    try {
      api
        .patch("/password", {
          oldPassword: formData.oldPassword,
          newPassword: formData.newPassword,
        })
        .then(function (res) {
          if (res.status === 201) {
            navigate("/");
          } else {
            setErrorMessage("Altes Passwort ist falsch!");
          }
        })
        .catch(function (error) {});
    } catch (error) {}
  };

  useEffect(() => {
    if (getSessionTimeout()) {
      setErrorMessage(
        "Deine Sitzung ist abgelaufen. Bitte melde dich erneut an!"
      );
    }
  });

  return (
    <section>
      <form className="change-password-form" onSubmit={handleSubmit}>
        <div className="form-item">
          <h2>Passwort ändern</h2>
        </div>
        <div className="form-item">
          <input
            name="oldPassword"
            onChange={handleChange}
            type="password"
            placeholder="Altes Passwort"
          />
          <input
            name="newPassword"
            onChange={handleChange}
            type="password"
            placeholder="Neues Passwort"
          />
          <input
            name="newPasswordConfirm"
            onChange={handleChange}
            type="password"
            placeholder="Neues Passwort bestätigen"
          />
          {errorMessage ? (
            <div className="change-password-error">{errorMessage}</div>
          ) : null}
          <input type="submit" value="Speichern" />
        </div>
      </form>
    </section>
  );
};
export default ChangePassword;
