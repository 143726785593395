import { Outlet } from "react-router-dom";
import MyNavbar from "../components/Navbar/Navbar";

export default function SharedLayout(props) {
  return (
    <>
      <MyNavbar />
      <main className="main-wrapper">
        <Outlet />
      </main>
    </>
  );
}
