import { useEffect, useRef } from "react";
import Example from "../../components/Example/Example";
import MyLatex from "../../components/MyLatex/MyLatex";
import ProblemsFromApi from "../../components/Problem/ProblemsFromApi";
import Trick from "../../components/Trick/Trick";
import "./HerbstzirkelZahlentheorie.css";

const HerbstzirkelZahlentheorie = () => {
  const problemsRef = useRef();

  const problemsSectionId = "problems";

  function scrollToProblems() {
    if (window.location.hash === "#" + problemsSectionId) {
      problemsRef.current.scrollIntoView();
      problemsRef.current.focus();
    }
  }

  return (
    <>
      <section className="problems-link-section">
        <a href="#problems">Zu den Aufgaben</a>
      </section>
      <section className="herbstzirkel-section">
        <h1>Größenabschätzungen in der Zahlentheorie</h1>

        <p>
          <MyLatex>
            In diesem Brief soll es um verschiedene Tricks gehen, wie man
            Größenabschätzungen in der Zahlentheorie anwenden kann. Ich möchte
            dazu zunächst drei technischere Aufgaben vorstellen, um zu
            illustrieren, wie man mit Größenordnungen umgehen kann. Die Aufgaben
            sollen dann einen Überblick schaffen, in welchen Situationen
            Größenabschätzungen sinnvoll sein können.
          </MyLatex>
        </p>
      </section>

      <section className="herbstzirkel-section">
        <h2>Polynomielle Gleichungen</h2>

        <p>
          <MyLatex>
            Wir beginnen mit ganzzahligen Lösungen zu polynomiellen Gleichungen.
            Polynome in einer Variablen kann man ziemlich gut handhaben: Wenn
            $x$ eine ganzzahlige Nullstelle eines Polynoms $a_nx^n + \ldots +
            a_1x + a_0$ mit ganzzahligen Koeffizienten ist, dann ist $x$
            sicherlich ein Teiler von $a_0 = -x \left(a_nx^&#123;n-1&#125; +
            \ldots + a_1\right)$, womit man nur noch endlich viele Werte für $x$
            durchprobieren muss. Etwa die Gleichung $5x^6 - x = 15x^2 + 1$ kann
            demnach nur $x = \pm 1$ als Lösungen haben, und eine Probe zeigt,
            dass nichts davon eine Lösung ist.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Ein anderer Ansatz für solche Gleichungen sind natürlich
            Größenordnungen: In $5x^6 - x = 15x^2 + 1$ hat die linke Seite Grad
            $6$, während die rechte Seite nur Grad $2$ hat. Für hinreichend
            große $x$ sollte also die linke Seite deutlich größer sein als die
            rechte. In der Praxis kann man so ein Argument gut aufziehen, indem
            man iterativ den größten Term gegen einen Term des nächstkleineren
            Grades abschätzt: Um hier etwa $5x^6 &gt; 15x^2 + x + 1$ zu
            erhalten, kann man $5x^6 = \left(5x^4\right) x^2$ schreiben, und für
            $x \geq 2$ ist das sicherlich mindestens $80x^2 = 15x^2 + 65x^2$.
            Nach demselben Prinzip ist $65x^2 \geq 130x &gt; x + 1$. Für $x \leq
            -2$ kann man analog argumentieren, somit bleiben endlich viele Fälle
            für $x$ übrig. Natürlich kann man nicht immer so großzügig
            abschätzen, oft bieten die Koeffizienten aber eben schon einen guten
            Anhaltspunkt.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Bei Polynomen in mehreren Variablen ist das allerdings nicht ganz so
            einfach. Das folgende Beispiel soll zeigen, wie man die Idee der
            Größenordnungen dennoch formalisieren kann:
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Bei Polynomen in mehreren Variablen ist das allerdings nicht ganz so
            einfach. Das folgende Beispiel soll zeigen, wie man die Idee der
            Größenordnungen dennoch formalisieren kann:
          </MyLatex>
        </p>

        <Example number="1 (IMO 2019/N2)">
          Man bestimme alle Tripel $(a, b, c)$ positiver ganzer Zahlen mit \[
          a^3 + b^3 + c^3 = (abc)^2. \]
        </Example>

        <p>
          <i>Lösung.</i>{" "}
          <MyLatex>
            Als Erstes kann man in dieser Aufgabe natürlich nach einfachen
            Lösungen suchen. Wenn man dabei allerdings nicht fündig wird, kann
            man auch so eine gute Intuition für die Aussage entwickeln: Die
            grundlegende Idee ist, dass die linke Seite Grad $3$ hat und damit
            „kleiner“ ist als die rechte Seite mit Grad $6$. Um einschränkende
            Informationen über $a$, $b$ und $c$ zu gewinnen, ist also die grobe
            Idee, Abschätzungen der Form \[ \text&#123;„etwas Kleines“&#125;
            \geq a^3 + b^3 + c^3 = (abc)^2 \geq \text&#123;„etwas Großes“&#125;
            \] zu benutzen, denn „etwas Kleines“ kann nur in wenigen Fällen
            größer sein als „etwas Großes“.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Um etwas konkreter zu werden, schätzen wir die linke Seite zunächst
            gegen die größte der drei Variablen ab. Dazu können wir
            o.&nbsp;B.&nbsp;d.&nbsp;A. $a \geq b \geq c$ annehmen, denn die
            Gleichung ist ja symmetrisch, und erhalten \[ 3a^3 \geq a^3 + b^3 +
            c^3 = (abc)^2. \] Der Vorteil hiervon ist, dass wir auf der linken
            Seite der Abschätzung jetzt nur noch die Variable $a$ stehen haben,
            was wir mit der rechten Seite kürzen können. Es folgt \[ 3a \geq
            b^2c^2, \text&#123;~~~~~ also ~~~~~&#125; a \geq
            \frac&#123;b^2c^2&#125;&#123;3&#125;. \] Das bedeutet, dass die
            größte Variable $a$ sogar deutlich größer sein muss als $b$ und $c$.
            Damit sehen wir, dass in der linken Seite der Gleichung der Term
            $a^3$ dominiert und $b^3 + c^3$ vergleichsweise klein ist.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Um damit weiterzuarbeiten, bietet sich die folgende Heuristik an:
            Wir betrachten die Differenz der dominierenden Terme beider Seiten,
            denn wir werden sehen, dass häufig \[ \text&#123;„etwas
            Großes“&#125; - \text&#123;„etwas Großes“&#125; = \text&#123;„etwas
            Großes“&#125; \] gilt. Konkret ist die Differenz $(abc)^2 - a^3$
            deswegen „groß“, weil wir den „großen“ Faktor $a^2$ ausklammern
            können: \[ b^3 + c^3 = (abc)^2 - a^3 = a^2 \cdot \left(b^2c^2 -
            a\right). \] In der Tat gilt laut obiger Feststellung $a^2 \geq
            \left(\frac&#123;b^2c^2&#125;&#123;3&#125;\right)^2 =
            \frac&#123;b^4c^4&#125;&#123;9&#125;$, was bereits mit Grad $8$ ein
            deutlich „größerer“ Term als die linke Seite $b^3 + c^3$ ist. Der
            zweite Faktor $b^2c^2 - a$ hingegen fällt nicht unbedingt ins
            Gewicht, denn a priori spricht nichts dagegen, dass $a \approx
            b^2c^2$ ist. Für uns ist nur entscheidend, dass er positiv ist; das
            sieht man mit dem Trick, dass man die ganze Gleichung ansieht: In
            $b^3 + c^3 = a^2 \cdot \left(b^2c^2 - a\right)$ ist die linke Seite
            positiv und ebenso der zweite Faktor $a^2$, damit muss auch $b^2c^2
            - a &gt; 0$ sein. Da es sich um eine ganze Zahl handelt, ist daher
            $b^2c^2 - a \geq 1$ und wir erhalten insgesamt die Abschätzung \[
            b^3 + c^3 = a^2 \cdot \left(b^2c^2 - a\right) \geq
            \frac&#123;b^4c^4&#125;&#123;9&#125;. \] Hier unterscheiden sich die
            Grade der linken und rechten Seite noch deutlicher als in der
            ursprünglichen Aufgabe. Daher ist es wieder wahrscheinlich, dass uns
            der allererste Trick weiterbringt, nämlich die kleinere linke Seite
            gegen die größte Variable abzuschätzen, die ja trotzdem im Vergleich
            zur rechten Seite „klein“ ist: Wir erhalten \[ 2b^3 \geq b^3 + c^3
            \geq \frac&#123;b^4c^4&#125;&#123;9&#125;, \] wodurch wir nach
            Kürzen zu $bc^4 \leq 18$ gelangen. Das sagt uns direkt, dass $c$
            sogar sehr klein sein muss: Da ja nach Annahme $b \geq c$ gilt,
            erhalten wir $c^5 \leq bc^4 \leq 18$, und wegen $18 &lt; 2^5$ ist
            das nur für $c = 1$ möglich.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Damit haben wir schon einmal eine Variable eliminert und die
            Gleichung auf \[ a^3 + b^3 + 1 = (ab)^2 \] reduziert. Jetzt lohnt es
            sich abermals, über die dominierenden Terme nachzudenken: Unsere
            Feststellung $a \geq \frac&#123;b^2c^2&#125;&#123;3&#125;$ von
            vorhin wird ja jetzt zu $a \geq \frac&#123;b^2&#125;&#123;3&#125;$,
            wodurch $a^3$ immer noch der dominierende Term der linken Seite ist.
            Wie oben können wir also die Differenz der dominierenden Terme
            betrachten und erhalten \[ b^3 + 1 = (ab)^2 - a^3 = a^2 \left(b^2 -
            a\right) \geq \frac&#123;b^4&#125;&#123;9&#125; \cdot 1 =
            \frac&#123;b^4&#125;&#123;9&#125;, \] also $b^4 \leq 9b^3 + 9$ (das
            ist exakt das Gleiche wie die obige Abschätzung $b^3 + c^3 \geq
            \frac&#123;b^4c^4&#125;&#123;9&#125;$). Jetzt steht eine
            polynomielle Ungleichung in einer Variablen da, wo wir ja sehr gut
            mit Größenordnungen arbeiten können: Der Koeffizient $9$ schränkt
            uns schon einmal auf $b \leq 9$ ein, denn für $b \geq 10$ wäre \[
            b^4 \geq 10b^3 = 9b^3 + b^3 \geq 9b^3 + 10^3 &gt; 9b^3 + 9. \] Wir
            erhalten also neun mögliche Werte für $b$. In einer
            Fallunterscheidung können wir damit neun verschiedene Gleichungen in
            der Variable $a$ lösen und so die Aufgabe beenden.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Wenn wir uns aber ein wenig geschickter anstellen, können wir die
            Anzahl an Fällen hier noch weiter reduzieren: Dafür müssen wir die
            Abschätzung $a \geq \frac&#123;b^2&#125;&#123;3&#125;$ verschärfen,
            die ja dadurch zustandegekommen ist, dass wir \[ 3a^3 \geq a^3 + b^3
            + 1 = (ab)^2 \] abgeschätzt haben (ursprünglich mit $c$ statt $1$).
            Wie können wir das verbessern?
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Nun ja, hierin verwenden wir ja die Abschätzungen $b^3 \leq a^3$ und
            $1 \leq a^3$, wovon letztere allerdings sehr unscharf ist: Denn
            falls $b &lt; a$ gilt, folgt direkt $b^3 &lt; a^3$, also $b^3 + 1
            \leq a^3$, womit sich die obige Abschätzung zu \[ 2a^3 \geq a^3 +
            b^3 + 1 = (ab)^2 \] verbessert. Hieraus erhalten wir dann $a \geq
            \frac&#123;b^2&#125;&#123;2&#125;$, sodass $a$ noch einmal stärker
            dominiert: Die Differenz der dominierenden Terme ist also \[ b^3 + 1
            = (ab)^2 - a^3 = a^2\left(b^2 - a\right) \geq
            \frac&#123;b^4&#125;&#123;4&#125; \cdot 1 =
            \frac&#123;b^4&#125;&#123;4&#125;, \] woraus wir $4b^3 + 4 \geq b^4$
            erhalten. Das ist bereits für $b \geq 5$ falsch (warum?), sodass wir
            nur noch vier mögliche Werte für $b$ durchprobieren müssen:
          </MyLatex>
        </p>

        <ul>
          <li>
            <b>Fall 1:</b>{" "}
            <MyLatex>
              $b = 2$. Die Gleichung wird zu $a^3 + 9 = 4a^2$. Das ist für $a
              \geq 4$ sicher falsch, denn dann ist $a^3 + 9 \geq 4a^2 + 9 &gt;
              4a^2$. Für $a=3$ erhalten wir die Lösung $(a, b, c) = (3, 2, 1)$,
              und für $a=2$ ist die Gleichung falsch (das zeigt man z.&nbsp;B.
              durch Nachrechnen, oder durch Betrachten der Gleichung modulo
              $2$). Da laut Annahme $a \geq b = 2$ gilt, sind alle Fälle
              abgedeckt.
            </MyLatex>
          </li>
          <li>
            <b>Fall 2:</b>{" "}
            <MyLatex>
              $b = 3$. Die Gleichung wird zu $a^3 + 28 = 9a^2$. Das ist für $a
              \geq 9$ schon sicher falsch, womit wir nur noch $3 \leq a \leq 8$
              durchprobieren müssen. Es geht aber auch schneller: Wenn wir die
              Gleichung umstellen und sie zu $28 = a^2 \cdot (9 - a)$
              ausklammern, sehen wir, dass $a^2$ ein Teiler von $28 = 2^2 \cdot
              7$ sein muss. Das ist für $a \geq b = 3$ unmöglich, weswegen es
              hier keine Lösung gibt.
            </MyLatex>
          </li>
          <li>
            <b>Fall 3:</b>{" "}
            <MyLatex>
              $b = 3$. Die Gleichung wird zu $a^3 + 28 = 9a^2$. Das ist für $a
              \geq 9$ schon sicher falsch, womit wir nur noch $3 \leq a \leq 8$
              durchprobieren müssen. Es geht aber auch schneller: Wenn wir die
              Gleichung umstellen und sie zu $28 = a^2 \cdot (9 - a)$
              ausklammern, sehen wir, dass $a^2$ ein Teiler von $28 = 2^2 \cdot
              7$ sein muss. Das ist für $a \geq b = 3$ unmöglich, weswegen es
              hier keine Lösung gibt.
            </MyLatex>
          </li>
          <li>
            <b>Fall 4:</b>{" "}
            <MyLatex>
              $b = 4$. Die Gleichung wird zu $a^3 + 65 = 16a^2$. Wie im
              vorherigen Fall bietet es sich an, $65 = a^2 \cdot (16-a)$ zu
              schreiben, um festzustellen, dass $a^2$ ein Teiler von $65 = 5
              \cdot 13$ ist. Das ist für $a \geq b = 4$ unmöglich.
            </MyLatex>
          </li>
        </ul>

        <p>
          <MyLatex>
            Was fehlt nun noch, um die Lösung zu vervollständigen?
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Zum einen dürfen wir den Fall $a=b$ nicht vergessen, den wir oben
            für unsere ausgeklammert haben. In diesem Sonderfall lautet die
            Gleichung $2a^3 + 1 = a^4$. Diese ist aber auch schnell gelöst: Wie
            üblich gilt für $a \geq 3$, dass $a^4 \geq 3a^3 = 2a^3 + a^3 &gt;
            2a^3 + 1$. Für $a \leq 2$ hingegen ist $a^4 \leq 2a^3 &lt; 2a^3 +
            1$, weswegen die Gleichheit nie eintritt.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Zum anderen dürfen wir bei der Lösung $(3, 2, 1)$ selbstverständlich
            die Probe nicht vergessen. Außerdem hatten wir ja $a \geq b \geq c$
            angenommen und müssen daher auch berücksichtigen, dass alle
            Permutationen von $(3, 2, 1)$ ebenfalls Lösungen sind. Mit dieser
            Angabe der Lösungsmenge ist die Aufgabe gelöst.
          </MyLatex>
        </p>

        <Trick>
          Bei diophantischen Gleichungen helfen oft einfache Abschätzungen,
          z.&nbsp;B. gegen die größte Variable.
        </Trick>

        <Trick>
          Betrachte die Differenz der dominierenden Terme: Diese ist oft selbst
          groß, weil man einen großen Faktor ausklammern kann!
        </Trick>
      </section>

      <section className="herbstzirkel-section">
        <h2>Abschätzungen mit Primfaktoren</h2>

        <p>
          <MyLatex>
            Sehr wichtig sind in der Zahlentheorie Abschätzungen mit Teilern,
            insbesondere Primfaktoren. Das ist auch die wichtigste
            zahlentheoretische Idee in der folgenden Aufgabe:
          </MyLatex>
        </p>

        <Example number="2 (IMO 2022/3)">
          Es seien $k$ eine positive ganze Zahl und $S$ eine endliche Menge
          ungerader Primzahlen. Man beweise, dass es (bis auf Drehung und
          Spiegelung) höchstens eine Möglichkeit gibt, die Elemente von $S$
          entlang eines Kreises so anzuordnen, dass das Produkt zweier
          beliebiger Nachbarn in der Form $x^2 + x + k$ mit einer geeigneten
          positiven ganzen Zahl $x$ dargestellt werden kann.
        </Example>

        <p>
          <i>Lösung.</i>{" "}
          <MyLatex>
            Um die Aufgabe zunächst ein wenig besser greifen zu können, bietet
            es sich an, sie graphentheoretisch zu formulieren: Seien $p_1,
            \ldots, p_n \in S$ die betrachteten Primzahlen. Wir betrachten dann
            einen Graphen mit diesen Primzahlen als Knoten, und verbinden zwei
            Primzahlen $p_i$ und $p_j$ genau dann, wenn ihr Produkt $p_ip_j$
            sich in der Form $x^2 + x + k$ mit einer positiven ganzen Zahl $x$
            schreiben lässt. Was ist dann die Behauptung in graphentheoretischer
            Sprache? Nun ja, zu zeigen ist, dass dieser Graph höchstens einen
            Hamiltonkreis (d.&nbsp;h. ein Kreis, der jeden Knoten genau einmal
            durchläuft) enthält.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Bevor wir uns das überlegen, wollen wir versuchen, überhaupt ein
            paar einschränkende Eigenschaften über Hamiltonkreise in dem Graphen
            bzw. Anordnungen der Primzahlen in einem Kreis wie in der
            Aufgabenstellung herauszufinden. Wenn es eine solche Anordnung gibt,
            o.&nbsp;B.&nbsp;d.&nbsp;A. in der Reihenfolge $p_1, \ldots, p_n$,
            dann gibt es positive ganze Zahlen $x_1, \ldots, x_n$ mit $$p_1p_2 =
            x_1^2 + x_1 + k$$ $$p_2p_3 = x_2^2 + x_2 + k $$ $$\vdots$$ $$p_np_1
            = x_n^2 + x_n + k. $$ Eine mögliche Idee, um dieses Gleichungssystem
            zu verarbeiten, ist, sie modulo jeder der Primzahlen zu betrachten:
            Und zwar gilt $x_&#123;i-1&#125;^2 + x_&#123;i-1&#125; + k \equiv
            x_i^2 + x_i + k \equiv 0 \pmod&#123;p_i&#125;$, insbesondere besitzt
            die Kongruenz $x^2 + x + k \equiv 0 \pmod&#123;p_i&#125;$ für jede
            Primzahl $p_i$ eine Lösung.
          </MyLatex>
        </p>
        <p>
          <MyLatex>
            An dieser Stelle möchte ich anmerken, dass sich quadratische
            Gleichungen modulo einer Primzahl $p$ sehr ähnlich verhalten wie in
            $\IR$: In $\IR$ hat die Gleichung $x^2 + x + k = 0$ die Lösungen
            $-\frac&#123;1&#125;&#123;2&#125; \pm
            \sqrt&#123;\frac&#123;1&#125;&#123;4&#125; - k&#125;$ hat, sofern
            $\frac&#123;1&#125;&#123;4&#125; - k$ nichtnegativ ist. Modulo $p$
            hingegen besitzt die Kongruenz $x^2 + x + k \equiv 0
            \pmod&#123;p&#125;$ völlig analog die Lösungen
            $-\frac&#123;1&#125;&#123;2&#125; \pm t$ (wobei mit
            $\frac&#123;1&#125;&#123;r&#125;$ das multiplikative Inverse eines
            Rests $r$ modulo $p$ gemeint ist), sofern es einen Rest $t$ mit $t^2
            \equiv \frac&#123;1&#125;&#123;4&#125; - k$ gibt, also
            $\frac&#123;1&#125;&#123;4&#125; - k$ ein quadratischer Rest ist:
            Dass dies Lösungen sind, kann man sich genauso wie in $\IR$ mit
            quadratischer Ergänzung überlegen, denn $x^2 + x + k \equiv 0$ ist
            äquivalent zu $\left(x + \frac&#123;1&#125;&#123;2&#125;\right)^2
            \equiv \frac&#123;1&#125;&#123;4&#125; - k$. Dass es keine weiteren
            Lösungen gibt, folgt aus der allgemeineren Aussage, dass ein Polynom
            $P$ vom Grad $d$ modulo $p$ höchstens $d$ Nullstellen haben kann:
            Analog zu $\IR$ kann man zu einer Nullstelle $r$ Polynomdivision mit
            Rest betreiben (das liegt daran, dass es modulo $p$ multiplikative
            Inverse gibt) und erhält, dass $x-r$ ein Faktor von $P$ sein muss.
            Diesen Prozess kann man höchstens $d$-mal wiederholen. (Dass $\IR$
            und die Menge $\mathbb&#123;F&#125;_p$ der Reste modulo $p$ sich
            hier ähnlich verhalten, liegt eben daran, dass man modulo $p$
            genauso rechnen kann wie in $\IR$, insbesondere kann man durch Reste
            $\neq 0$ dividieren. Man sagt abstrakt, dass $\IR$ und
            $\mathbb&#123;F&#125;_p$
          </MyLatex>{" "}
          <i>Körper</i> sind.)
        </p>

        <p>
          <MyLatex>
            So weit, so gut. Was bringt uns dieser Gedanke jetzt für die
            Aufgabe? Nun ja, eine einfache Folgerung ist, dass es überhaupt
            keinen Hamiltonkreis gibt, wenn $\frac&#123;1&#125;&#123;4&#125; -
            k$ kein quadratischer Rest modulo $p$ ist. Für solche $k$ haben wir
            die Behauptung also schon bewiesen. Dieser Ansatz allein wird die
            Aufgabe aber nicht lösen (tatsächlich gibt es Beispiele für Mengen
            von Primzahlen, zu denen es genau eine gewünschte Anordnung gibt).
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Ein weiterer Gedanke für quadratische Gleichungen ist, sich nicht
            nur über die Reste der Lösungen Gedanken zu machen, sondern auch
            über ihre konkrete Größe. Dazu betrachten wir eine Gleichung
            $p_ip_&#123;i+1&#125; = x_i^2 + x_i + k$. Die linke Seite ist hier
            gar nicht allzu groß, denn wenn $p = \max(p_i, p_&#123;i+1&#125;)$
            gilt, dann ist $p_ip_&#123;i+1&#125; &lt; p^2$. Damit folgt aber
            $x_i^2 &lt; x_i^2 + x_i + k &lt; p^2$, also $x_i &lt; p$. Das
            entspricht also einer Größenabschätzung über die Grade beider Seiten
            der Gleichung.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Hieran ist nun vor allem interessant, dass $x_i$ fast eindeutig
            festgelegt ist: Denn da es höchstens zwei Reste mit $x^2 + x + k
            \equiv 0 \pmod&#123;p&#125;$ gibt, gibt es auch höchstens zwei
            Zahlen $x &lt; p$ mit dieser Eigenschaft. Insbesondere kann $p_i$ in
            unserem betrachteten Graphen nicht mit mehr als zwei kleineren
            Primzahlen verbunden sein: Denn falls $q, r, s &lt; p_i$ Primzahlen
            sind und $$ p_iq = X^2 + X + k, $$ $$ p_ir = Y^2 + Y + k, $$ $$ p_is
            = Z^2 + Z + k $$ gilt, dann sind $0 &lt; X, Y, Z &lt; p$ Lösungen zu
            $x^2 + x + k \equiv 0 \pmod&#123;p&#125;$, weswegen laut
            Schubfachprinzip zwei der Variablen $X$, $Y$, $Z$ gleich sein müssen
            und daher auch zwei der Primzahlen $q$, $r$, $s$. Durch Beschränken
            der Lösungen zu einer Kongruenz kann man also mit
            Eindeutigkeits-Argumenten arbeiten.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Hiermit folgt schon einmal, dass jede Primzahl in unserem Graphen
            mit höchstens zwei kleineren Primzahlen verbunden ist. Insbesondere
            hat die größte Primzahl höchstens zwei Nachbarn, was für die
            Eindeutigkeit des Hamiltonkreises noch nützlich werden könnte.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Bevor wir uns aber weiter über den Graphen Gedanken machen, wollen
            wir unser Gleichungssystem noch etwas weiter ausschlachten. Eine
            weitere Möglichkeit, dieses zu verarbeiten, ist, die Differenz
            zweier aufeinanderfolgender Gleichungen betrachten: Demnach gilt $$
            p_i \left(p_&#123;i+1&#125; - p_&#123;i-1&#125;\right) =
            x_&#123;i+1&#125;^2 - x_i^2 + x_&#123;i+1&#125; - x_i
            \text&#123;~~~~~ für alle $i$&#125;, $$ wobei wir alle Indizes
            modulo $n$ betrachten. Hier haben wir einerseits das ominöse $k$
            eliminiert, das das Gleichungssystem ein wenig schlecht greifbar
            macht. Andererseits können wir die rechte Seite weiter faktorisieren
            und erhalten $$ p_i \left(p_&#123;i+1&#125; -
            p_&#123;i-1&#125;\right) = \left(x_i -
            x_&#123;i-1&#125;\right)\left(x_i + x_&#123;i-1&#125;\right) + x_i -
            x_&#123;i-1&#125; = \left(x_i - x_&#123;i-1&#125;\right)\left(x_i +
            x_&#123;i-1&#125; + 1\right). \tag&#123;*&#125; $$ Da $p_i$ eine
            Primzahl ist, folgt $p_i \mid x_i - x_&#123;i-1&#125;$ oder $p_i
            \mid x_i + x_&#123;i-1&#125; + 1$. Das ist schon einmal eine weitere
            nette Bedingung an die Zahlen $x_i$. Aus (*) können wir aber
            deutlich mehr herausholen: Dafür hilft uns wieder der Trick, nicht
            nur irgendeine Primzahl $p_i$ zu betrachten, sondern die größte,
            denn laut unseren obigen Überlegungen folgt daraus
            $x_&#123;i-1&#125; &lt; p_i$ und $x_i &lt; p_i$. Dann können wir die
            Größe der Faktoren in (*) sehr gut analysieren: Daraus folgt dann
            nämlich $|x_i - x_&#123;i-1&#125;| &lt; p_i$. Das schließt dann $p_i
            \mid x_i - x_&#123;i-1&#125;$ aus: Allgemein folgt aus $a \mid b$,
            dass $b = 0$ oder $a \leq |b|$ (den Betrag sollte man nicht
            vergessen, denn $b$ kann an auch negativ sein!). Da aber aus
            $x_&#123;i-1&#125; = x_i$ laut Gleichungssystem folgen würde, dass
            $p_&#123;i-1&#125; = p_&#123;i+1&#125;$, was zumindest für $n \geq
            3$ absurd ist, gilt $x_i - x_&#123;i-1&#125; \neq 0$, sodass in der
            Tat $p_i \nmid x_i - x_&#123;i-1&#125;$ ist. Wir erhalten für die
            größte Primzahl also $p_i \mid x_i + x_&#123;i-1&#125; + 1$.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Aber damit nicht genug: Bei Resultaten der Form $a \mid b$ ist es
            öfters sinnvoll, sich auch zu fragen, wie groß $b$ denn konkret ist
            (also $k$ zu bestimmen oder zumindest einzuschränken, wenn $b = k
            \cdot a$ ist). Tatsächlich ist in unserem Fall ja $x_i,
            x_&#123;i-1&#125; &lt; p_i$, also \[ 0 &lt; x_i + x_&#123;i-1&#125;
            + 1 \leq (p_i-1) + (p_i-1) + 1 &lt; 2p_i, \] und das ist nur mit
            $p_i \mid x_i + x_&#123;i-1&#125; + 1$ vereinbar, wenn sogar \[ x_i
            + x_&#123;i-1&#125; + 1 = p_i. \] gilt. Insgesamt haben wir also nur
            durch Subtrahieren aufeinanderfolgender Gleichungen und
            Größenabschätzungen der Faktoren (nach Wahl der größten Primzahl
            $p_i$) Informationen über die Zahlen $x_i$ bei einer möglichen
            Anordnung der Primzahlen in einem Kreis gefunden.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Damit ist der zahlentheoretische Teil der Aufgabe tatsächlich schon
            abgehandelt. Jetzt brauchen wir eine kombinatorische Idee, um zu
            zeigen, dass es in unserem Graphen nur einen Hamiltonkreis gibt. Wir
            wissen ja bereits, dass der Knoten $p_i$ höchstens zwei Nachbarn
            hat, wenn $p_i$ die größte Primzahl ist. Habt ihr eine Idee, was man
            damit machen kann?
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Der entscheidende Trick ist eine Induktion: Dafür wollen wir zeigen,
            dass in dem Graphen auch die Knoten $p_&#123;i-1&#125;$ und
            $p_&#123;i+1&#125;$ verbunden sind. Wenn es nun zwei verschiedene
            Hamiltonkreise gäbe, dann müssten beide den Knoten $p_i$ durch die
            einzigen anliegenden Kanten zu $p_&#123;i-1&#125;$ und
            $p_&#123;i+1&#125;$ betreten. Durch Entfernen dieser Kanten aus den
            Hamiltonkreisen und Einfügen der Kante von $p_&#123;i-1&#125;$ zu
            $p_&#123;i+1&#125;$ würden sich dadurch aber zwei verschiedene
            Hamiltonkreise auf dem Teilgraphen der Knoten $p_1, \ldots,
            p_&#123;i-1&#125;, p_&#123;i+1&#125;, \ldots p_n$ ergeben, und das
            können wir nach Induktionsvoraussetzung ausschließen. Der
            Induktionsanfang $n=2$ ist natürlich leicht zu sehen und für $n=1$
            ist die Aussage auch klar (warum kann man die Induktion nicht bei
            $n=1$ beginnen?).
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Konkret wollen wir also zeigen, dass auch
            $p_&#123;i-1&#125;p_&#123;i+1&#125;$ von der Form $x^2 + x + k$ ist.
            Was wissen wir dabei über $p_&#123;i-1&#125;$ und
            $p_&#123;i+1&#125;$? Nun ja, laut Voraussetzung gilt $$
            p_&#123;i-1&#125;p_i = x_&#123;i-1&#125;^2 + x_&#123;i-1&#125; + k,
            $$ $$ p_ip_&#123;i+1&#125; = x_i^2 + x_i + k. $$ Außerdem haben wir
            ja herausgefunden, dass \[ p_i = x_i + x_&#123;i-1&#125; + 1. \]
            Daraus wollen wir nun folgern, dass die Gleichung
            $p_&#123;i-1&#125;p_&#123;i+1&#125; = x^2 + x + k$ eine positive
            ganzzahlige Lösung besitzt. Da wir nun wissen, worauf wir
            hinauswollen, können wir die Lösung mit dem Kopf durch die Wand
            fertigstellen: Einsetzen der obigen Gleichungen liefert \[
            p_&#123;i-1&#125;p_&#123;i+1&#125; =
            \frac&#123;\left(x_&#123;i-1&#125;^2 + x_&#123;i-1&#125; +
            k\right)\left(x_i^2 + x_i + k\right)&#125;&#123;\left(x_i +
            x_&#123;i-1&#125; + 1\right)^2&#125;. \] Um die folgende Rechnung
            etwas übersichtlicher zu machen, sei $a = x_&#123;i-1&#125;$ und $b
            = x_i$. Wir suchen also eine Lösung zur Gleichung \[ x^2 + x +
            \left(k - p_&#123;i-1&#125;p_&#123;i+1&#125;\right) = x^2 + x +
            \left(k - \frac&#123;(a^2 + a + k)(b^2 + b + k)&#125;&#123;(a + b +
            1)^2&#125;\right) = 0. \] Die reellen Lösungen sind laut
            $p$-$q$-Formel gegeben durch \[ -\frac&#123;1&#125;&#123;2&#125; \pm
            \sqrt&#123;\frac&#123;1&#125;&#123;4&#125; - k + \frac&#123;(a^2 + a
            + k)(b^2 + b + k)&#125;&#123;(a + b + 1)^2&#125;&#125;. \] Wir
            wollen zeigen, dass diese Lösungen ganzzahlig sind. Dafür
            vereinfachen wir die Wurzel zunächst zu $$
            \sqrt&#123;\frac&#123;(a+b+1)^2 - 4k(a+b+1)^2 +
            4(a^2+a+k)(b^2+b+k)&#125;&#123;4(a+b+1)^2&#125;&#125; $$ $$ =
            \frac&#123;\sqrt&#123;1 + 2 a + a^2 + 2 b + 6 a b + 4 a^2 b + b^2 +
            4 a b^2 + 4 a^2 b^2 - 4 k - 4 a k - 4 b k - 8 a b k + 4
            k^2&#125;&#125;&#123;2(a+b+1)&#125;. $$ Um zu zeigen, dass die
            Lösungen zu der Gleichung ganzzahlig sind, wäre es vorteilhaft, wenn
            der Term unter der Wurzel im Zähler eine Quadratzahl ist. Wovon
            könnte dieser Term das Quadrat sein?
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Nun ja, ein Anhaltspunkt sind die vorkommenden Quadrate $1$, $a^2$,
            $b^2$, $4a^2b^2$ und $4k^2$, was einen auf einen Ansatz der Form \[
            (1 \pm a \pm b \pm 2ab \pm 2k)^2 \] bringt. Da nur die Terme mit $k$
            negatives Vorzeichen haben, ist der Term unter der Wurzel in der Tat
            gleich \[ (1 + a + b + 2ab - 2k)^2. \] Damit lauten die reellen
            Lösungen zur obigen Gleichung \[ - \frac&#123;1&#125;&#123;2&#125;
            \pm \frac&#123;1 + a + b + 2ab - 2k&#125;&#123;2(a+b+1)&#125; =
            -\frac&#123;1&#125;&#123;2&#125; \pm
            \left(\frac&#123;1&#125;&#123;2&#125; + \frac&#123;ab -
            k&#125;&#123;a + b + 1&#125;\right). \] Diese Zahlen sind auf jeden
            Fall rational. Um zu zeigen, dass die beiden Lösungen auch
            ganzzahlig sind, müssen wir zeigen, dass $ab-k$ durch $a+b+1$
            teilbar ist. Dafür erinnern wir uns zurück, dass $p_i = a + b + 1$
            war, wir wollen also $ab \equiv k \pmod&#123;p_i&#125;$ zeigen.
            Dabei ist $b = p_i - a - 1 \equiv -a-1 \pmod&#123;p_i&#125;$, also
            \[ ab \equiv -a(a+1) \equiv -a^2 - a \pmod&#123;p_i&#125;. \]
            Gleichzeitig ist natürlich $p_&#123;i-1&#125;p_i = a^2 + a + k$ und
            daher in der Tat \[ ab \equiv -a^2 - a \equiv k \] Also hat die
            Gleichung $x^2 + x + (k - p_&#123;i-1&#125;p_&#123;i+1&#125;) = 0$
            sicherlich zwei ganzzahlige Lösungen. Für die
            Induktionsvoraussetzung benötigen wir dabei noch, dass eine dieser
            Lösungen positiv ist: Das ist wichtig, weil wir in den Abschätzungen
            ganz oben benutzt haben, dass $x_i$ und $x_&#123;i-1&#125;$ positiv
            sind (wo genau?). Wir wissen auf jeden Fall, dass eine der beiden
            Lösungen nichtnegativ ist (denn $-\frac&#123;1&#125;&#123;2&#125; +
            \left\vert\frac&#123;1&#125;&#123;2&#125; + \frac&#123;ab -
            k&#125;&#123;a + b + 1&#125;\right\vert$ ist eine ganze Zahl größer
            als $-\frac&#123;1&#125;&#123;2&#125;$ und damit nichtnegativ). Mehr
            brauchen wir aber im Endeffekt auch gar nicht, denn wenn wir in der
            Aufgabe auch $x = 0$ zulassen, dann funktionieren unsere
            Abschätzungen von oben immer noch (warum?). Damit erhalten wir auf
            jeden Fall, dass $x^2 + x + (k -
            p_&#123;i-1&#125;p_&#123;i+1&#125;)$ eine nichtnegative ganzzahlige
            Lösung besitzt, und sind in der Tat, wie oben beschrieben, induktiv
            fertig. $\qed$
          </MyLatex>
        </p>

        <Trick>
          Um zu zeigen, dass ein Graph genau einen Hamiltonkreis besitzt, ist
          ein Knoten mit genau zwei Nachbarn nützlich: Wenn seine Nachbarn auch
          durch eine Kante verbunden sind, kann man Induktion anwenden.
          Allgemein reicht es auch, irgendeinen Knoten zu finden, dessen
          Nachbarn auch paarweise durch eine Kante verbunden sind (warum?).
        </Trick>

        <Trick>
          Eine quadratische Gleichung besitzt modulo $p$ höchstens zwei
          Lösungen.
        </Trick>

        <Trick>
          Bei Gleichungen modulo $p$ kann es sich lohnen, die Größe der Lösungen
          einzuschränken, um Eindeutigkeitsargumente zu benutzen.
        </Trick>

        <Trick>
          In einem Gleichungssystem lohnt es sich, Gleichungen zu subtrahieren,
          um sie zu faktorisieren.
        </Trick>

        <Trick>
          In faktorisierten Gleichungen lohnt es sich, über die Größen der
          Faktoren nachzudenken.
        </Trick>

        <Trick>
          Für Größenabschätzungen sind extremale Faktoren (hier die größte
          Primzahl) nützlich. Etwa folgt aus $a \mid b$, dass $b = 0$ oder $a
          \leq |b|$.
        </Trick>

        <Trick>
          Um zu zeigen, dass ein Ausdruck von der Form $x^2 + x + k$ ist, kann
          man $x$ im Zweifel mit dem Kopf durch die Wand mithilfe der
          $p$-$q$-Formel ausrechnen.
        </Trick>
      </section>

      <section className="herbstzirkel-section">
        <h2>Eine analytisch angehauchte Aufgabe</h2>

        <p>
          <MyLatex>
            Die folgende Aufgabe ist technisch anspruchsvoll, soll aber in
            Analogie zu Beispiel 1 verdeutlichen, dass es bei Abschätzungen oft
            nicht auf konkrete Konstanten, sondern nur auf Größenordnungen
            ankommt.
          </MyLatex>
        </p>

        <Example number={"2 (IMO 2016/N8)"}>
          Man bestimme alle Polynome $P(x)$ vom Grad $d$ mit ganzzahligen
          Koeffizienten und folgender Eigenschaft: Für jede positive ganze Zahl
          $n$ existieren positive ganze Zahlen $x_1, \ldots, x_n$, sodass für
          alle $i, j \in \&#123;1, \ldots, n\&#125;$ der Term
          $\frac&#123;P(x_i)&#125;&#123;P(x_j)&#125;$ die $d$-te Potenz einer
          rationalen Zahl ist und \[ \frac&#123;1&#125;&#123;2&#125; &lt;
          \frac&#123;P(x_i)&#125;&#123;P(x_j)&#125; &lt; 2 \] erfüllt.
        </Example>

        <p>
          <i>Bemerkung.</i>{" "}
          <MyLatex>
            In der Aufgabenstellung aus der Shortlist soll man nur alle solchen
            Polynome mit ungeradem Grad bestimmen, der folgende Beweis stimmt
            aber für beliebige Grade.
          </MyLatex>
        </p>

        <p>
          <i>Lösung.</i>{" "}
          <MyLatex>
            Wie üblich ist es immer eine gute Idee, zunächst nach Lösungen zu
            suchen. Lasst uns dafür die beiden Bedingungen an die Quotienten
            $\frac&#123;P(x_i)&#125;&#123;P(x_j)&#125;$ getrennt betrachten:
          </MyLatex>
        </p>

        <ul>
          <li>
            <MyLatex>
              Dass es beliebig lange Folgen $x_1, \ldots, x_n$ mit
              $\frac&#123;1&#125;&#123;2&#125; &lt;
              \frac&#123;P(x_i)&#125;&#123;P(x_j)&#125; &lt; 2$ für alle $i$,
              $j$ gibt, ist nichts Ungewöhnliches und gilt einfach für jedes
              Polynom: Wenn $P(x) = a_dx^d + \ldots + a_1x + a_0$ ist, dann gilt
              für hinreichend große $x$, dass $P(x) \approx a_dx^d$. Genauer
              gesagt geht $\frac&#123;P(x)&#125;&#123;a_d x^d&#125; = 1 +
              \frac&#123;a_&#123;d-1&#125;&#125;&#123;a_dx&#125; + \ldots +
              \frac&#123;a_1&#125;&#123;a_dx^&#123;d-1&#125;&#125; +
              \frac&#123;a_0&#125;&#123;a_dx^d&#125;$ für $x \to \pm \infty$
              gegen $1$, weswegen ab einer gewissen Schranke $S$ für alle $x
              \geq S$ gilt $0,9 \cdot a_dx^d &lt; P(x) &lt; 1,1 \cdot a_dx^d$
              (dabei kann man $0,9$ und $1,1$ natürlich durch Zahlen beliebig
              nahe an $1$ ersetzen). Wenn jetzt aber $x_i, x_j &gt; S$ sind,
              dann folgt $\frac&#123;P(x_i)&#125;&#123;P(x_j)&#125; &gt;
              \frac&#123;0,9 \cdot a_dx_i^d&#125;&#123;1,1 \cdot a_dx_j^d&#125;
              &gt; 0,8 \left(\frac&#123;x_i&#125;&#123;x_j&#125;\right)^d$, und
              sofern $\frac&#123;x_i&#125;&#123;x_j&#125; &gt;
              \sqrt[d]&#123;\frac&#123;5&#125;&#123;8&#125;&#125;$ ist, folgt
              $\frac&#123;P(x_i)&#125;&#123;P(x_j)&#125; &gt;
              \frac&#123;1&#125;&#123;2&#125;$. Analog folgt für
              $\frac&#123;x_i&#125;&#123;x_j&#125; &lt;
              \sqrt[d]&#123;\frac&#123;8&#125;&#123;5&#125;&#125;$, dass
              $\frac&#123;P(x_i)&#125;&#123;P(x_j)&#125; &lt; 2$. Man findet
              jetzt aber sicherlich beliebig viele verschiedene Zahlen $x_1,
              \ldots, x_n$, für die $\frac&#123;x_i&#125;&#123;x_j&#125;$ in
              diesem eingeschränkten Bereich liegt: Etwa kann man $x_1$ so groß
              wählen, dass $\frac&#123;x_1 + n&#125;&#123;x_1&#125; = 1 +
              \frac&#123;n&#125;&#123;x_1&#125; &lt;
              \sqrt[d]&#123;\frac&#123;8&#125;&#123;5&#125;&#125;$ ist, und dann
              $x_i = x_1 + i-1$ setzen.
            </MyLatex>
          </li>
          <li>
            <MyLatex>
              Das Besondere an dieser Aufgabe ist hingegen, dass
              $\frac&#123;P(x_i)&#125;&#123;P(x_j)&#125;$ die $d$-te Potenz
              einer rationalen Zahl ist. Für welche Polynome ist das zum
              Beispiel erfüllt?
            </MyLatex>
          </li>
        </ul>

        <p>
          <MyLatex>
            Die zweite Bedingung bringt uns darauf, dass zum Beispiel $P(x) =
            x^d$ sicherlich eine Lösung ist; mit ein wenig mehr Scharfsinn sieht
            man auch, dass Polynome der Form $P(x) = ax^d$ eine Lösung
            darstellen. Aber sind das auch wirklich die einzigen Lösungen?
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Die Antwort auf diese Frage lautet nein; ich werde euch die Lösungen
            allerdings erst später verraten. Lasst uns zunächst versuchen,
            vorwärts an die Aufgabe heranzugehen. Wir haben die entscheidende
            Bedingung auszunutzen, dass
            $\frac&#123;P(x_i)&#125;&#123;P(x_j)&#125;$ die $d$-te Potenz einer
            rationalen Zahl ist. Quantitativ ist also etwa \[
            \frac&#123;P(x_1)&#125;&#123;P(x_2)&#125; = \frac&#123;a_dx_1^d +
            \ldots + a_1x_1 + a_0&#125;&#123;a_dx_2^d + \ldots + a_1x_2 +
            a_0&#125; = \frac&#123;y_1^d&#125;&#123;y_2^d&#125; ~~~~~
            \text&#123;für $y_1, y_2 \in \IZ$ mit $\ggT(y_1, y_2) = 1$&#125;. \]
            Die zweite Bedingung $\frac&#123;1&#125;&#123;2&#125; &lt;
            \frac&#123;P(x_1)&#125;&#123;P(x_2)&#125; =
            \frac&#123;y_1^d&#125;&#123;y_2^d&#125; &lt; 2$ lässt sich etwas
            besser veranschaulichen, wenn man sie als $P(x_1) \approx P(x_2)$
            bzw. $y_1^d \approx y_2^d$ notiert. Daraus folgt dann insbesondere
            $y_1 \approx y_2$. Genauer gesagt ist natürlich
            $\frac&#123;1&#125;&#123;\sqrt[d]&#123;2&#125;&#125; &lt;
            \frac&#123;y_1&#125;&#123;y_2&#125; &lt; \sqrt[d]&#123;2&#125;$,
            fürs Erste wollen wir aber nur über Größenordnungen nachdenken.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Unser Ziel ist es ja, $P(x) = a_dx^d$ zu beweisen, also
            $a_&#123;d-1&#125; = \ldots = a_0 = 0$. Dabei werden wir uns zunutze
            machen, dass natürlich ohnehin $P(x) \approx a_dx^d$ gilt und damit
            \[ \frac&#123;P(x_1)&#125;&#123;P(x_2)&#125; \approx
            \frac&#123;a_dx_1^d&#125;&#123;a_dx_2^d&#125; =
            \frac&#123;x_1^d&#125;&#123;x_2^d&#125;. \] Ganz grob
            heruntergebrochen gilt also $\frac&#123;x_1^d&#125;&#123;x_2^d&#125;
            \approx \frac&#123;y_1^d&#125;&#123;y_2^d&#125;$. Die entscheidende
            Idee in dieser Aufgabe ist es nun, dass zwei $d$-te Potenzen
            rationaler Zahlen, die nah beieinander liegen, entweder identisch
            sind oder sich in ihrem Zähler und Nenner jeweils stark
            unterscheiden. Der Beweis hiervon beruht auf folgender Intuition: Es
            gilt ja $$ \frac&#123;x_1^d + \text&#123;„etwas
            Kleines“&#125;&#125;&#123;x_2^d + \text&#123;„etwas
            Kleines“&#125;&#125; = \frac&#123;y_1^d&#125;&#123;y_2^d&#125;, \tag
            &#123;1&#125; $$ wobei „etwas Kleines“ hier für
            $\frac&#123;a_&#123;d-1&#125;x_i^&#123;d-1&#125; + \ldots +
            a_0&#125;&#123;a_d&#125;$ steht (der dominierende Term im Zähler und
            Nenner ist auf jeden Fall $x_i^d$). Daraus folgt nach Multiplikation
            mit dem Hauptnenner \[ x_1^dy_2^d + y_2^d \cdot \text&#123;„etwas
            Kleines“&#125; = x_2^d y_1^d + y_1^d \cdot \text&#123;„etwas
            Kleines“&#125;. \] Die dominierenden Terme hierin sind $x_1^dy_2^d$
            und $x_2^dy_1^d$. Wir werden im Verlauf der Lösung noch sehen, dass
            ihre Differenz auch wieder groß sein muss, sofern sie nicht $0$ ist
            (ähnlich zu Beispiel 1), weswegen \[ y_1^d \cdot \text&#123;„etwas
            Kleines“&#125; - y_2^d \cdot \text&#123;„etwas Kleines“&#125; =
            x_1^d y_2^d - x_2^d y_1^d = \text&#123;„etwas Großes“&#125; \] sein
            muss. Damit die linke Seite hier aber „etwas Großes“ ist, können
            $y_1$ und $y_2$ jeweils nicht allzu klein sein. Wir werden aber
            sehen, dass wir das ausschließen können; damit muss der andere Fall
            eintreten, dass die Differenz $x_1^dy_2^d - x_2^dy_1^d = 0$ ist, es
            wird also $\frac&#123;x_1^d&#125;&#123;x_2^d&#125; =
            \frac&#123;y_1^d&#125;&#123;y_2^d&#125; =
            \frac&#123;P(x_1)&#125;&#123;P(x_2)&#125;$ folgen. Es wird sich
            herausstellen, dass man daraus dann $P(x) = ax^d$ folgern kann.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Lasst uns nun etwas quantitativer aufschreiben, was das „Kleine“ in
            (1) ist. Wir haben \[ \frac&#123;P(x_1)&#125;&#123;P(x_2)&#125; =
            \frac&#123;a_dx_1^d + a_&#123;d-1&#125;x_1^&#123;d-1&#125; + \ldots
            + a_0&#125;&#123;a_dx_2^d + a_&#123;d-1&#125;x_2^&#123;d-1&#125; +
            \ldots + a_0&#125; = \frac&#123;y_1^d&#125;&#123;y_2^d&#125;, \]
            woraus nach Multiplikation mit dem Hauptnenner \[ y_2^d \cdot
            \left(a_dx_1^d + a_&#123;d-1&#125;x_1^&#123;d-1&#125; + \ldots +
            a_0\right) = y_1^d \cdot \left(a_dx_2^d +
            a_&#123;d-1&#125;x_2^&#123;d-1&#125; + \ldots + a_0\right) \] folgt.
            Wieder wollen wir die Größenordnungen beider Seiten analysieren und
            betrachten dafür die Differenz der dominierenden Terme (mit dem
            Ziel, die große Seite nach unten und die kleine Seite nach oben
            abzuschätzen): $$ a_dx_1^dy_2^d - a_dx_2^dy_1^d = y_1^d \cdot
            \left(a_&#123;d-1&#125;x_2^&#123;d-1&#125; + \ldots + a_0\right) -
            y_2^d \cdot \left(a_&#123;d-1&#125;x_1^&#123;d-1&#125; + \ldots +
            a_0\right). \tag&#123;2&#125; $$ Jetzt wollen wir auch formal
            begründen, warum die linke Seite groß ist, indem wir einen großen
            Faktor ausklammern (ähnlich zu Beispiel 1): Es gilt nämlich $$
            a_dx_1^dy_2^d - a_dx_2^dy_1^d = a_d \left(x_1y_2 - x_2y_1\right)
            \left(x_1^&#123;d-1&#125;y_2^&#123;d-1&#125; + x_1^&#123;d-2&#125;
            y_2^&#123;d-2&#125; x_2 y_1 + \ldots + x_2^&#123;d-1&#125;
            y_1^&#123;d-1&#125;\right). \tag&#123;3&#125; $$ Dabei können wir
            $x_1$ und $x_2$ sowie $y_1$ und $y_2$ gut miteinander vergleichen:
            Zumindest für hinreichend große $x_i$ ist, wie am Anfang
            beschrieben, $\frac&#123;P(x_i)&#125;&#123;P(x_j)&#125; \approx
            \frac&#123;a_dx_i^d&#125;&#123;a_dx_j^d&#125; =
            \left(\frac&#123;x_i&#125;&#123;x_j&#125;\right)^d$ (wobei wir im
            Folgenden $A \approx B$ schreiben, wenn es Konstanten $c_1, c_2 &gt;
            0$ gibt mit $c_1 &lt; \frac&#123;A&#125;&#123;B&#125; &lt; c_2$).
            Wegen $\frac&#123;1&#125;&#123;2&#125; &lt;
            \frac&#123;P(x_i)&#125;&#123;P(x_j)&#125; &lt; 2$, also
            $\frac&#123;P(x_i)&#125;&#123;P(x_j)&#125; \approx 1$, ist also auch
            $\left(\frac&#123;x_i&#125;&#123;x_j&#125;\right)^d \approx 1$ und
            damit $x_i \approx x_j$. (Es sei euch überlassen, euch zu überlegen,
            dass aus $A \approx B \approx C$ auch $A \approx C$ folgt.) Analog
            ist auch $\frac&#123;1&#125;&#123;2&#125; &lt;
            \frac&#123;y_i^d&#125;&#123;y_j^d&#125; &lt; 2$ und damit $y_i
            \approx y_j$.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Wie erwähnt, brauchen wir hier, dass die $x_i$ und $y_i$ hinreichend
            groß ist. Das ist aber kein Problem, denn da es ja zu jedem $n$ eine
            Folge $x_1, \ldots, x_n$ mit der Eigenschaft aus der
            Aufgabenstellung gibt, treten für hinreichend große $n$ auch
            hinreichend große Folgenglieder auf, auf die wir uns beschränken
            können.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Wegen $x_1 \approx x_2$ und $y_1 \approx y_2$ ist der zweite Faktor
            in (3) also von der Größenordnung in etwa $d \cdot
            x_1^&#123;d-1&#125; y_1^&#123;d-1&#125; \approx x_1^&#123;d-1&#125;
            y_1^&#123;d-1&#125;$. Wie in Beispiel 1 haben wir zwar keine
            konkrete Information über den ersten Faktor $x_1y_2 - x_2y_1$,
            solange er aber von $0$ verschieden ist, hat er mindestens Betrag
            $1$. Wir erhalten also \[ \left| a_dx_1^dy_2^d - a_dx_2^dy_1^d
            \right| \gtrapprox \left| a_d d \cdot
            x_1^&#123;d-1&#125;y_1^&#123;d-1&#125; \right| \approx \left|
            x_1^&#123;d-1&#125; y_1^&#123;d-1&#125; \right|, \] wobei auch hier
            mit $A \gtrapprox B$ gemeint ist, dass es eine Konstante $c &gt; 0$
            mit $\frac&#123;A&#125;&#123;B&#125; &gt; c$ gibt. Die Beträge
            brauchen wir, weil die Terme durchaus negativ werden können; für
            ihre Größenordnung ist aber nur der Betrag relevant. Damit haben wir
            den Gedanken, dass die linke Seite von (2) „groß“ ist, in Formeln
            gefasst.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Jetzt wollen wir noch die rechte Seite nach oben abschätzen, um zu
            zeigen, dass sie „klein“ ist: Wegen $x_1 \approx x_2$ und $y_1
            \approx y_2$ bewegt diese sich höchstens in der Größenordnung von
            $a_&#123;d-1&#125; x_1^&#123;d-1&#125; y_1^&#123;d-1&#125; \approx
            x_1^&#123;d-1&#125; y_1^d$. Damit erhalten wir zusammen \[ \left|
            x_1^&#123;d-1&#125;y_1^&#123;d-1&#125; \right| \lessapprox \left|
            a_dx_1^dy_2^d - a_dx_2^dy_1^d \right| = \left| y_1^d \cdot
            \left(a_&#123;d-1&#125;x_2^&#123;d-1&#125; + \ldots + a_0\right) -
            y_2^d \cdot \left(a_&#123;d-1&#125;x_1^&#123;d-1&#125; + \ldots +
            a_0\right) \right| \lessapprox \left| x_1^&#123;d-1&#125; y_1^d
            \right|. \] Völlig analog zu Beispiel 1 können wir hier auf beiden
            Seiten kürzen und erhalten \[ \left| y_1 \right| \gtrapprox 1. \]
            Das ist leider wenig überraschend: Der Grund, warum unsere obige
            Heuristik aus (1) nicht aufgeht, ist, dass der „kleine“ Term
            $a_&#123;d-1&#125; x^&#123;d-1&#125; + \ldots + a_1 x + a_0$ im
            Vergleich zu $x^d$ nur einen Grad kleiner ist, während wir in (3)
            bei der Abschätzung $|x_1y_2 - x_2y_1| \geq 1$ einen Grad
            verschenken.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Hier hilft uns jetzt aber der folgende Trick, um die Abschätzung um
            einen Grad zu verschärfen: Um den „kleinen“ Term
            $a_&#123;d-1&#125;x^&#123;d-1&#125; + \ldots + a_1x + a_0$ noch
            weiter zu verkleinern, substituieren wir $x =
            \tilde&#123;x&#125;+z$, sodass der Koeffizient vor
            $\tilde&#123;x&#125;^&#123;d-1&#125;$ in $P(\tilde&#123;x&#125;+z) =
            a_d (\tilde&#123;x&#125;+z)^d + a_&#123;d-1&#125;
            (\tilde&#123;x&#125;+z)^&#123;d-1&#125; + \ldots$ verschwindet.
            Konkret rechnet man nach, dass das für $z = -
            \frac&#123;a_&#123;d-1&#125;&#125;&#123;d a_d&#125;$ der Fall ist.
            Dann erfüllt das Polynom $P_1(x) = P(x+z) \coloneqq b_dx^d +
            b_&#123;d-2&#125;x^&#123;d-2&#125; + \ldots + b_1x + b_0$ ebenfalls
            die Eigenschaft aus der Aufgabe (nämlich dass es zu jedem $n$ Zahlen
            $\tilde&#123;x&#125;_1, \ldots, \tilde&#123;x&#125;_n$ gibt, sodass
            $P(\tilde&#123;x&#125;_i) \approx P(\tilde&#123;x&#125;_j)$ gilt und
            $\frac&#123;P(\tilde&#123;x&#125;_i)&#125;&#123;P(\tilde&#123;x&#125;_j)&#125;$
            die $d$-te Potenz einer rationalen Zahl ist), bis auf zwei kleine
            Probleme: Durch die Substitution $x \mapsto \tilde&#123;x&#125; + z$
            werden die $x_i$ nicht zwingend in ganze Zahlen überführt, und die
            Koeffizienten von $P_1$ sind nicht zwingend ganzzahlig, sondern nur
            rational. Das zweite Problem ist schnell behoben, indem wir mit dem
            Hauptnenner $h$ der Koeffizienten $b_i$ von $P_1$ multiplizieren und
            das erhaltene Polynom $P_2(x) = h \cdot P_1(x) = (hb_d) x^d +
            (hb_&#123;d-2&#125;) x^&#123;d-2&#125; + \ldots$ betrachten. Das
            erste Problem ist ebenfalls nicht schwer zu beheben: Wenn $z_1$ der
            Nenner von $z$ ist, dann betrachten wir \[ P_3(x) = z_1^d \cdot
            P_2(x) = hz_1^d P_1(x) = (hb_d) (z_1x)^d + (hb_&#123;d-2&#125;z_1^2)
            (z_1x)^&#123;d-2&#125; + \ldots + (hb_0z_1^d), \] womit wir effektiv
            $\tilde&#123;x&#125;_i = x_i - z$ mit ihrem Nenner hochmultipliziert
            haben. Das Polynom \[ Q(x) \coloneqq (hb_d) x^d + (hb_&#123;d -
            2&#125;z_1^2) x^&#123;d - 2&#125; + \ldots + (hb_0z_1^d) \] erfüllt
            nun also wirklich die Eigenschaften aus der Aufgabenstellung: Seine
            Koeffizienten sind ganzzahlig, und zu jeder Folge $x_1, \ldots,
            x_n$, die die geforderte Eigenschaft an $P$ erfüllt, ist
            $z_1(x_1-z), \ldots, z_1(x_n-z)$ eine Folge von Zahlen, die
            geforderte Eigenschaft an $Q$ erfüllt.
          </MyLatex>
        </p>
        <p>
          <MyLatex>
            Lange Rede, kurzer Sinn: Durch eine lineare Substitution können wir
            o.&nbsp;B.&nbsp;d.&nbsp;A. annehmen, dass der Koeffizient
            $a_&#123;d-1&#125; = 0$ ist, also $P(x) = a_dx^d +
            a_&#123;d-2&#125;x^&#123;d-2&#125; + \ldots + a_1x + a_0$ gilt.
            Dadurch wird der „kleine“ Term $a_&#123;d-2&#125; x^&#123;d-2&#125;
            + \ldots$ um einen Grad kleiner, was uns gleich in der Abschätzung
            voranbringen wird. Beachtet allerdings noch, dass durch die obige
            Substitution zu einer Lösung $P(x)$ dann auch $A \cdot P(Bx + C)$
            für $A, B, C \in \IZ$ eine Lösung ist. Insbesondere erhalten wir
            damit neben $P(x) = ax^d$ auch \[ P(x) = A \cdot (Bx + C)^d
            \text&#123;~~~~ für $A, B, C \in \IZ$ (mit $A \neq 0$)&#125; \] als
            Lösungen. Wenn wir also zeigen können, dass jede Lösung nach der
            linearen Substitution, die den Koeffizienten vor $x^&#123;d-1&#125;$
            entfernt, in ein Polynom der Form $P(x) = ax^d$ übergeht, dann ist
            jede Lösung von jener Form $P(x) = A \cdot (Bx + C)^d$.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            In der Tat bringt uns die Reduktion auf $a_&#123;d-1&#125; = 0$
            jetzt in der Größenabschätzung in (2) weiter: Es folgt nämlich $$
            a_dx_1^dy_2^d - a_dx_2^dy_1^d = y_1^d \cdot
            \left(a_&#123;d-2&#125;x_2^&#123;d-2&#125; + \ldots + a_0\right) -
            y_2^d \cdot \left(a_&#123;d-2&#125;x_1^&#123;d-2&#125; + \ldots +
            a_0\right), \tag&#123;4&#125; $$ und unsere Größenabschätzung von
            vorhin verschärft sich zu $$ \left|
            x_1^&#123;d-1&#125;y_1^&#123;d-1&#125; \right| \lessapprox \left|
            a_dx_1^dy_2^d - a_dx_2^dy_1^d \right| = \left| y_1^d \cdot
            \left(a_&#123;d-2&#125;x_2^&#123;d-2&#125; + \ldots + a_0\right) -
            y_2^d \cdot \left(a_&#123;d-2&#125;x_1^&#123;d-2&#125; + \ldots +
            a_0\right) \right| \lessapprox \left| x_1^&#123;d-2&#125; y_1^d
            \right|. \tag&#123;5&#125; $$ Die obige Arbeit, den Koeffizienten
            $a_&#123;d-1&#125;$ loszuwerden, zahlt sich jetzt endlich aus, denn
            beim Kürzen erhalten wir die wertvollere Aussage \[ y_1 \gtrapprox
            x_1, \] was genau unsere anfängliche Intuition in Formeln fasst,
            dass $y_1$ nicht allzu klein sein kann. Es gibt also eine Konstante
            $c &gt; 0$, sodass $y_1 &gt; cx_1$ gilt (in einer Reinschrift könnte
            man natürlich genauer in den Abschätzungen aufdröseln, wie groß die
            Konstanten jeweils sind; der Kürze halber möchte ich das an dieser
            Stelle aber auslassen). Diese Konstante $c$ hängt dabei nur von den
            Koeffizienten von $P(x)$ ab, aber nicht von den eingesetzten Werten
            $x_i$ selbst (sofern diese hinreichend groß sind).
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Tatsächlich können wir die Abschätzung in (5) sogar noch weiter
            verschärfen, nur diesmal in der Abschätzung von (3) nach unten.
            Dafür wollen wir erzwingen, dass $x_1y_2 - x_2y_1$ einen großen
            (Prim-)Teiler $p$ besitzt, denn dann folgt sogar $|x_1y_2 - x_2y_1|
            \geq p$, außer es gilt schon $x_1y_2 - x_2y_1 = 0$. Die Idee dafür
            ist, $x_1 \equiv x_2 \pmod&#123;p&#125;$ zu wählen und daraus $y_1
            \equiv y_2 \pmod&#123;p&#125;$ zu folgern: Zunächst folgt nämlich
            $P(x_1) \equiv P(x_2) \pmod&#123;p&#125;$. Sofern $p$ eine Primzahl
            ist und $P(x_1) \equiv P(x_2) \nequiv 0 \pmod&#123;p&#125;$ gilt,
            folgt wegen $P(x_1) y_2^d = P(x_2) y_1^d$, dass auch $y_1^d \equiv
            y_2^d \pmod&#123;p&#125;$ ist. Um jetzt auf $y_1 \equiv y_2
            \pmod&#123;p&#125;$ zu kommen, benutzen wir einen Trick zum kleinen
            Satz von Fermat: Sofern $d$ ein Vielfaches, sagen wir $kd$, mit $kd
            \equiv 1 \pmod&#123;p-1&#125;$ besitzt, folgt laut Fermat $y_1
            \equiv y_1^&#123;kd&#125; \equiv \left(y_1^d\right)^k \equiv
            \left(x_1^d\right)^k \equiv x_1^&#123;kd&#125; \equiv x_1$. Dafür
            brauchen wir also, dass $d$ teilerfremd zu $p-1$ ist, und um das zu
            erreichen, können wir $p \equiv 2 \pmod&#123;d&#125;$ wählen (der
            Satz von Dirichlet garantiert die Existenz unendlich vieler solcher
            $p$), denn dann gilt $d \mid (p-1)-1$. Sofern unter den $x_i$ in der
            Aufgabenstellung also zwei Werte $x_1 \equiv x_2 \pmod&#123;p&#125;$
            mit $P(x_1) \equiv P(x_2) \nequiv 0 \pmod&#123;p&#125;$ vorkommen,
            erhalten wir, dass $x_1y_2 - x_2y_1$ durch $p$ teilbar ist, wodurch
            sich (5) noch weiter zu \[ p \cdot \left| x_1^&#123;d-1&#125;
            y_1^&#123;d-1&#125; \right| \lessapprox \ldots \lessapprox
            \left|x_1^&#123;d-2&#125; y_1^d \right|, \text&#123;~~~ also
            ~~~&#125; y_1 &gt; p \cdot cx_1 \] verschärft. Da $p$ ja beliebig
            groß werden kann, folgt daraus, dass $y_1$ im Vergleich zu $x_1$
            beliebig groß werden kann. Das steht aber im Widerspruch zur
            Definition von $y_1$, denn $\frac&#123;y_1^d&#125;&#123;y_2^d&#125;$
            ist ein vollständig gekürzter Bruch und gleich zu
            $\frac&#123;P(x_1)&#125;&#123;P(x_2)&#125;$, weswegen $y_1^d$
            sicherlich nicht größer werden kann als $P(x_1)$. Da für hinreichend
            große $x$ ja $P(x) \lessapprox a_dx^d$ gilt, muss also $y_1^d
            \lessapprox x_1^d$ bzw. $y_1 \lessapprox x_1$ gelten. Das steht im
            Widerspruch zu $y &gt; p \cdot cx_1$, weil $p$ ja beliebig groß
            werden kann (und die Konstante in $y_1 \lessapprox x_1$ nicht von
            $p$ abhängt).
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Wenn also $x_1 \equiv x_2 \pmod&#123;p&#125;$ gilt und $P(x_1)
            \equiv P(x_2) \nequiv 0 \pmod&#123;p&#125;$, dann muss $x_1y_2 -
            x_2y_1 = 0$ sein, weil sonst die linke Seite von (2) zu groß wäre.
            Aber muss es denn überhaupt solche $x_1, x_2$ geben?
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Nun ja, $x_1 \equiv x_2 \pmod&#123;p&#125;$ tritt für jede
            vorgegebene Primzahl $p$ sogar beliebig oft auf: Da es beliebig
            lange Folgen $x_1, \ldots, x_n$ mit der Eigenschaft aus der
            Aufgabenstellung gibt, ergeben sich nach Schubfachprinzip für $n
            &gt; p$ sicherlich zwei gleiche Reste. Was aber tun wir, wenn
            $P(x_1) \equiv P(x_2) \equiv 0 \pmod&#123;p&#125;$ ist?
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Die Antwort darauf liegt wiederum in der Definition von $y_1$ und
            $y_2$: Da $\frac&#123;y_1^d&#125;&#123;y_2^d&#125;$ durch Kürzen von
            $\frac&#123;P(x_1)&#125;&#123;P(x_2)&#125;$ entsteht und hierin
            Zähler und Nenner durch $p$ teilbar sind, folgt $y_1^d \leq
            \frac&#123;P(x_1)&#125;&#123;p&#125; \approx
            \frac&#123;a_dx_1^d&#125;&#123;p&#125;$, also $y_1 \lessapprox
            \frac&#123;x_1&#125;&#123;\sqrt[d]&#123;p&#125;&#125;$. Das steht
            aber jetzt im Widerspruch zu $y_1 \gtrapprox x_1$, denn $p$ kann ja
            beliebig groß werden. Damit haben wir in der Tat den Fall $P(x_1)
            \equiv P(x_2) \equiv 0 \pmod&#123;p&#125;$ ausgeschlossen. Wir
            finden also insgesamt zu einem geeignet gewählten $p \equiv 2
            \pmod&#123;d&#125;$ unter den Folgen aus der Aufgabenstellung zwei
            hinreichend große Zahlen $x_1, x_2$ mit $x_1y_2 - x_2y_1 = 0$ bzw.
            $\frac&#123;x_1&#125;&#123;x_2&#125; =
            \frac&#123;y_1&#125;&#123;y_2&#125;$ und damit
            $\frac&#123;P(x_1)&#125;&#123;P(x_2)&#125; =
            \frac&#123;y_1^d&#125;&#123;y_2^d&#125; =
            \frac&#123;x_1^d&#125;&#123;x_2^d&#125;$.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Damit verhält sich $P(x)$ also an zwei Stellen sehr ähnlich zu
            $x^d$. Den letzten Feinschliff können wir der Lösung jetzt noch
            verpassen, indem wir ausnutzen, dass zwei Polynome identisch sind,
            wenn sie an beliebig vielen Stellen übereinstimmen. Konkret sieht
            das hier so aus: Zu einer hinreichend großen Primzahl $p \equiv 2
            \pmod&#123;d&#125;$ finden wir in den Folgen aus der
            Aufgabenstellung nicht nur zwei Werte $x_1 \equiv x_2
            \pmod&#123;p&#125;$, sondern sogar beliebig viele Werte $x_1 \equiv
            \ldots \equiv x_k \pmod&#123;p&#125;$. Wenn wir jetzt eine der
            Variablen fixieren, sagen wir $x_1$, dann folgt analog zu oben für
            alle anderen $i$, dass $\frac&#123;P(x_i)&#125;&#123;P(x_1)&#125; =
            \frac&#123;x_i^d&#125;&#123;x_1^d&#125;$. Damit stimmt $P(x)$ an
            beliebig vielen Stellen (nämlich $x_2, \ldots, x_k$) mit dem Polynom
            $P'(x) = \frac&#123;P(x_1)&#125;&#123;x_1^d&#125; x^d$ überein. Für
            $k \geq d+2$ sind das mehr Stellen als der Grad von $P$, sodass $P'$
            und $P$ identisch sind (denn ihre Differenz hätte höchstens $d$
            Nullstellen, wenn sie nicht $0$ wäre). Daher muss $P(x)$ selbst
            schon von der Form $P(x) = a_dx^d$ sein, womit wir unsere Vermutung
            bewiesen haben.
          </MyLatex>
        </p>

        <p>
          <MyLatex>
            Zusammen mit der linearen Substitution sind also alle Lösungen von
            der Form $P(x) = A \cdot (Bx + C)^d$, und eine Probe bestätigt, dass
            dies wirklich Lösungen sind.
          </MyLatex>
        </p>

        <Trick>
          Um Größenordnungen abzuschätzen, kann auch nur ein grobes Gefühl für
          die auftretenden Konstanten reichen!
        </Trick>

        <Trick>
          Bei einem Polynom $P(x) = a_dx^d + a_&#123;d-1&#125;x^&#123;d-1&#125;
          + \ldots$ gilt für hinreichend große $x$, dass $P(x) \approx a_dx^d
          \approx x^d$ (wobei mit $A \approx B$ gemeint ist, dass es Konstanten
          $c_1, c_2 &gt; 0$ mit $c_1 &lt; \frac&#123;A&#125;&#123;B&#125; &lt;
          c_2$ gibt).
        </Trick>

        <Trick>
          Durch eine Substitution der Unbekannten eines Polynoms $P(x) = a_dx^d
          + a_&#123;d-1&#125;x^&#123;d-1&#125; + \ldots$ kann man den
          Koeffizienten $a_&#123;d-1&#125;$ verschwinden lassen und somit
          erzwingen, dass $a_dx^d$ noch stärker dominiert.
        </Trick>

        <Trick>
          Eine Abschätzung eines „kleinen“ Faktors gegen $1$ kann man
          verschärfen, indem man erzwingt, dass er einen großen Teiler besitzt:
          Denn aus $a \mid b$ folgt $b = 0$ oder $|b| \geq a$.
        </Trick>

        <Trick>
          Wenn $\frac&#123;a&#125;&#123;b&#125; =
          \frac&#123;A&#125;&#123;B&#125;$ gilt und
          $\frac&#123;a&#125;&#123;b&#125;$ vollständig gekürzt ist, dann ist
          $|a| &lt; |A|$ und $|b| &lt; |B|$.
        </Trick>

        <Trick>
          Wenn sich zwei Polynome „ähnlich“ verhalten (hier
          $\frac&#123;P(x)&#125;&#123;P(y)&#125; =
          \frac&#123;x^d&#125;&#123;y^d&#125;$ an beliebig vielen Stellen), dann
          kann man möglicherweise über deren Grade argumentieren, dass sie schon
          identisch sind.
        </Trick>
      </section>

      <section
        className="herbstzirkel-section"
        id={problemsSectionId}
        ref={problemsRef}
      >
        <h2>Aufgaben</h2>

        <p>
          <MyLatex>
            Die folgenden Aufgaben haben alle etwas mit Größenabschätzungen (im
            weiteren Sinne) zu tun. Bitte gebt eure Ansätze und Lösungen bis zum
            14.12.2023 ab. Viel Spaß beim Lösen!
          </MyLatex>
        </p>

        <ProblemsFromApi
          apiUrl={"/problems/number-theory"}
          showSubmitLink={true}
          showDashboardLink={true}
          onSuccess={scrollToProblems}
        />
      </section>
    </>
  );
};
export default HerbstzirkelZahlentheorie;
