import "./ErrorServerDown.css";

const ErrorServerDown = () => {
  return (
    <div className="error-server-down-wrapper">
      <h2>Herbstzirkel 2023</h2>
      <p>
        Es konnte keine Verbindung zum Server aufgebaut werden. Bitte lade die
        Seite neu oder überprüfe deine Verbindung!
      </p>
      <p>
        Falls das Problem nicht in den nächsten Minuten behoben ist, melde dich
        bitte per Mail bei mir!
      </p>
    </div>
  );
};

export default ErrorServerDown;
