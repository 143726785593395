import { Route, Routes } from "react-router-dom";
import SharedLayout from "../pages/SharedLayout";
import { AuthData } from "./AuthWrapper";
import { nav } from "./Navigation";

const RenderRoutes = () => {
  const { user } = AuthData();

  return (
    <Routes>
      <Route path="/" element={<SharedLayout />}>
        {nav.map((route, i) => {
          if (!route.isAdmin || user.role === "admin") {
            return <Route key={i} path={route.path} element={route.element} />;
          }
          return null;
        })}
      </Route>
    </Routes>
  );
};

export default RenderRoutes;
