import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { api } from "../../components/Api";
import { AuthData } from "../../components/AuthWrapper";
import MyLatex from "../../components/MyLatex/MyLatex";
import ProblemByUrl from "../../components/Problem/ProblemByUrl";
import "./Submissions.css";

const Submission = ({ submission, index }) => {
  // const checkboxId = useId();

  return (
    <div
      className={
        "submission " +
        (submission.score !== null
          ? "border-score" + submission.score
          : "border-pending")
      }
    >
      <div
        className={
          "submission-header " +
          (submission.score !== null ? "score" + submission.score : "pending")
        }
      >
        <div className="submission-header-index">
          Abgabe{" "}
          {submission?.problem_number
            ? "zu Aufgabe " + submission?.problem_number
            : index}
        </div>
        <div className="submission-header-score">
          {submission.score !== null
            ? submission.score + "/7 Punkte"
            : "noch nicht bewertet"}
        </div>
      </div>
      <div className="submission-content">
        {/*<div className="submission-date">
          Datum: {moment(submission.datetime).format("DD.MM.YYYY, HH:MM")}
          </div>*/}
        {/*submission.feedback && (
          <>
            <input type="checkbox" id={checkboxId} defaultChecked={true} />{" "}
            <label htmlFor={checkboxId}>Feedback anzeigen</label>
          </>
        )*/}
        <div className="submission-feedback">
          <MyLatex>{submission.feedback ?? submission.solution}</MyLatex>
        </div>
        {/*<div className="submission-code">
          <code>{submission.solution}</code>
        </div>*/}
      </div>
    </div>
  );
};

const Submissions = () => {
  const { user, checkToken } = AuthData();
  const [submissions, setSubmissions] = useState(null);
  const { problemUrl } = useParams();

  const [problemName, setProblemName] = useState(null);
  const loadingMessage = "Laden...";
  const [message, setMessage] = useState(loadingMessage);

  useEffect(() => {
    const fetchProblem = async () => {
      try {
        api.get(`/problem/${problemUrl}`).then((res) => {
          if (res.data.message === "Not authorized") {
            checkToken();
          } else if (res.data && res.data.length !== 0) {
            setProblemName(res.data[0].number);
            setMessage("");
          } else {
            setProblemName();
            setMessage("Ungültige Aufgabe.");
          }
        });
      } catch (err) {}
    };

    if (problemUrl !== undefined) {
      fetchProblem();
    } else {
      setProblemName("");
      setMessage("");
    }
  }, [problemUrl, checkToken]);

  useEffect(() => {
    const fetchSubmissions = async () => {
      let apiUrl;
      setSubmissions(null);
      if (problemUrl) {
        apiUrl = "/submissions/" + user.name + "/" + problemUrl;
      } else {
        apiUrl = "/submissions/" + user.name;
      }
      try {
        api.get(apiUrl).then((res) => {
          if (res.data.message === "Not authorized") {
            checkToken();
          } else {
            setSubmissions(res.data);
          }
        });
      } catch (err) {}
    };
    fetchSubmissions();
  }, [user, problemUrl, checkToken]);

  if (submissions === null) {
    return <p>{loadingMessage}</p>;
  } else {
    return (
      <>
        {problemName === undefined ? (
          message
        ) : (
          <>
            {problemUrl !== undefined ? (
              <>
                <ProblemByUrl url={problemUrl} showSubmitLink={true} />
                {/*<p>
                  <NavLink className="a-button" to={"/submit/" + problemUrl}>
                    Neue Abgabe
                  </NavLink>
            </p>*/}
              </>
            ) : (
              <p>
                <NavLink to="/number-theory#problems">Zu den Aufgaben</NavLink>
              </p>
            )}

            <div className="submitted-submissions">
              {submissions.length === 0 && (
                <p>
                  Du hast {problemName && "zu dieser Aufgabe"} noch nichts
                  abgegeben.
                </p>
              )}
              {submissions.map((submission, i, a) => (
                <Submission
                  key={i}
                  index={a.length - i}
                  submission={submission}
                />
              ))}
            </div>
          </>
        )}
      </>
    );
  }
};

export default Submissions;
