import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../components/Api";
import LatexEditor from "../../components/LatexEditor/LatexEditor";
import { AuthData } from "../../components/AuthWrapper";

const Feedback = () => {
  const { checkToken } = AuthData();

  const navigate = useNavigate();

  const localStorageKey = "feedback";

  const [feedback, setFeedback] = useState({ feedback: "" });

  const handleChange = (value) => {
    setFeedback((prev) => ({ ...prev, feedback: value }));
    setSubmitMessage("");

    if (value !== "") {
      localStorage.setItem(localStorageKey, value);
    } else {
      localStorage.removeItem(localStorageKey);
    }
  };

  const [submitMessage, setSubmitMessage] = useState(null);

  const handleClick = async (e) => {
    e.preventDefault();
    if (feedback.feedback !== null && feedback.feedback.trim() !== "") {
      try {
        api
          .post("/feedback", feedback)
          .then((res) => {
            if (res?.data?.message === "Not authorized") {
              checkToken();
            } else {
              localStorage.removeItem(localStorageKey);
              setSubmitMessage("Erfolgreich abgesendet!");
              navigate(0);
            }
          })
          .catch((err) => {
            setSubmitMessage(
              "Es konnte keine Verbindung zum Server aufgebaut werden. Bitte versuche es noch einmal oder überprüfe deine Verbindung!"
            );
          });
      } catch (err) {}
    } else {
      setSubmitMessage("Bitte gib dein Feedback ein!");
    }
  };

  const [loadingEditor, setLoadingEditor] = useState(true);

  useEffect(() => {
    setFeedback((prev) => ({
      ...prev,
      feedback: localStorage.getItem(localStorageKey),
    }));
    setLoadingEditor(false);
  }, [localStorageKey]);

  return (
    <section className="submit-feedback-wrapper">
      <h1>Feedback geben</h1>
      <p>
        Hinweis: Das Feedback wird anonym gespeichert. Falls du möchtest, dass
        ich deinen Namen sehe, schreibe ihn bitte ins Feedback.
      </p>
      <div className="form">
        {loadingEditor ? (
          <p>Lade Editor...</p>
        ) : (
          <LatexEditor
            handleChangeFunc={handleChange}
            defaultValue={feedback.feedback}
            placeholder={
              "Gib hier dein Feedback ein!\n\nFormeln kannst du in LaTeX schreiben: $a^2 + b^2 = c^2$.\n\nFür Formeln in einer getrennten Zeile benutze\n$$ a^2 \\equiv 1 \\pmod{p}. $$\n\nMit \\tag{} kannst du Formeln nummerieren:\n$$ a \\mid b - 1. \\tag{1} $$"
            }
          />
        )}

        {submitMessage && <div className="submit-message">{submitMessage}</div>}

        <input type="button" value="Absenden" onClick={handleClick} />
      </div>
    </section>
  );
};

export default Feedback;
