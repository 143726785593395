import { useEffect, useState } from "react";
import { api } from "../Api";
import Problem from "./Problem";
import { AuthData } from "../AuthWrapper";

const ProblemsFromApi = ({
  apiUrl,
  showSubmitLink = null,
  showDashboardLink = null,
  onSuccess = () => {},
}) => {
  const [problems, setProblems] = useState([]);
  const { checkToken } = AuthData();

  useEffect(() => {
    const fetchAllProblems = async () => {
      try {
        api.get(apiUrl).then((res) => {
          if (res.data.message === "Not authorized") {
            checkToken();
          } else {
            setProblems(res.data);
          }
        });
      } catch (err) {}
    };
    fetchAllProblems();
  }, [apiUrl, checkToken]);

  useEffect(() => {
    if (problems != []) {
      onSuccess();
    }
  });

  return (
    <>
      {problems.map((problem, i) => (
        <Problem
          key={i}
          number={problem.number}
          url={problem.url}
          showSubmitLink={showSubmitLink}
          showDashboardLink={showDashboardLink}
        >
          {problem.statement}
        </Problem>
      ))}
    </>
  );
};

export default ProblemsFromApi;
