import { useState } from "react";
import MyLatex from "../MyLatex/MyLatex";
import "./LatexEditor.css";

const LatexEditor = ({ handleChangeFunc, defaultValue, placeholder }) => {
  const [solution, setSolution] = useState(defaultValue ?? "");

  const handleChange = (e) => {
    handleChangeFunc(e.target.value);
    setSolution(e.target.value);
  };

  const defaultPlaceholder =
    "Gib hier deine Ideen ein!\n\nFormeln kannst du in LaTeX schreiben: $a^2 + b^2 = c^2$.\n\nFür Formeln in einer getrennten Zeile benutze\n$$ a^2 \\equiv 1 \\pmod{p}. $$\n\nMit \\tag{} kannst du Formeln nummerieren:\n$$ a \\mid b - 1. \\tag{1} $$";

  return (
    <div className="latex-editor">
      <div className="textarea-wrapper">
        <div className="textarea-caption">Eingabe</div>
        <textarea
          name="solution"
          onChange={handleChange}
          placeholder={placeholder ?? defaultPlaceholder}
          defaultValue={defaultValue ?? undefined}
        ></textarea>
      </div>
      <div className="preview-wrapper">
        <div className="preview-caption">Vorschau</div>
        <div className="preview">
          <MyLatex>{solution}</MyLatex>
        </div>
      </div>
    </div>
  );
};

export default LatexEditor;
